html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img,
fieldset,
a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
  background: none;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input,
button {
  margin: 0;
  padding: 0;
  border: 0;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

ul,
ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-arrow-down-fat {
  width: 1.33em;
  height: 1em;
  fill: #fff;
}

.icon-arrow-left {
  width: 0.6em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-next-fat {
  width: 1.3em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-next {
  width: 1.2em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-prev {
  width: 1.2em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-right {
  width: 0.6em;
  height: 1em;
  fill: #11142d;
}

.icon-arrow-up-fat {
  width: 1.33em;
  height: 1em;
  fill: #fff;
}

.icon-arrows {
  width: 0.63em;
  height: 1em;
  fill: #11142d;
}

.icon-bag {
  width: 0.95em;
  height: 1em;
  fill: #1b1d21;
}

.icon-bell {
  width: 0.92em;
  height: 1em;
  fill: #11142d;
}

.icon-box {
  width: 1.13em;
  height: 1em;
  fill: #11142d;
}

.icon-chart {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-chat {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-comment {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-discovery {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-document {
  width: 0.95em;
  height: 1em;
  fill: #1b1d21;
}

.icon-dots-border {
  width: 4em;
  height: 1em;
  fill: #11142d;
}

.icon-download-fat {
  width: 0.94em;
  height: 1em;
  fill: #11142d;
}

.icon-download {
  width: 0.95em;
  height: 1em;
  fill: #7fba7a;
}

.icon-edit {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-editor-bold {
  width: 0.83em;
  height: 1em;
  fill: #fff;
}

.icon-editor-italic {
  width: 0.5em;
  height: 1em;
  fill: #808191;
}

.icon-editor-pic {
  width: 1.08em;
  height: 1em;
  fill: #808191;
}

.icon-editor-smile {
  width: 1em;
  height: 1em;
  fill: #808191;
}

.icon-folder {
  width: 1.06em;
  height: 1em;
  fill: #11142d;
}

.icon-like {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-logout {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-message {
  width: 1.11em;
  height: 1em;
  fill: #1b1d21;
}

.icon-moon {
  width: 1em;
  height: 1em;
  fill: #3f8cff;
}

.icon-more {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-notification {
  width: 0.91em;
  height: 1em;
  fill: #1b1d21;
}

.icon-overview {
  width: 0.95em;
  height: 1em;
  fill: #fff;
}

.icon-plus-square {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-print {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-profile {
  width: 0.81em;
  height: 1em;
  fill: #1b1d21;
}

.icon-remove {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-search {
  width: 1em;
  height: 1em;
  fill: #11142d;
}

.icon-settings {
  width: 0.91em;
  height: 1em;
  fill: #1b1d21;
}

.icon-star {
  width: 1em;
  height: 1em;
  fill: #1b1d21;
}

.icon-sun {
  width: 1em;
  height: 1em;
  fill: #3f8cff;
}

.icon-wallet {
  width: 1.1em;
  height: 1em;
  fill: #1b1d21;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url("./imowl.video.play.png") no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 100ms ease;
  transition: -webkit-transform 100ms ease;
  -o-transition: transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

body {
  min-width: 375px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #11142d;
}

body.dark {
  background: #242731;
  color: #ffffff;
}

body.no-scroll {
  overflow: hidden;
}

button,
input,
textarea,
select {
  font-family: "Inter", sans-serif;
}

img {
  display: block;
  max-width: 100%;
}

body.dark .apexcharts-tooltip.apexcharts-theme-light {
  color: #11142d;
}

body.dark .apexcharts-gridlines-horizontal line {
  stroke: rgba(228, 228, 228, 0.1);
}

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.page__wrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 256px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.page__center {
  max-width: 1200px;
  margin: 0 auto;
}

.page__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page__row_head .page__col {
  padding-top: 48px;
}

.page__row_border .page__col:nth-child(2) {
  border-left: 1px solid #e4e4e4;
}

.page__col {
  padding: 0 64px 44px;
}

.page__col:first-child {
  width: 100%;
}

.page__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 426px;
}

.page .page__col.page__col_w50 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.page__widgets {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -16px;
}

.page__widgets:not(:last-child) {
  margin-bottom: 43px;
}

.page__widgets .widget {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 32px);
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px;
}

.page__group .widget:not(:last-child) {
  margin-bottom: 69px;
}

.page__content {
  padding: 0 64px 64px;
}

.page__content_pt64 {
  padding-top: 64px;
  border-top: 1px solid #e4e4e4;
}

.page__stat {
  position: relative;
  padding: 50px 32px 40px;
  border-radius: 24px;
  background: #ffffff;
}

.page__stat:before {
  content: "";
  position: absolute;
  top: 43px;
  left: 32px;
  right: 32px;
  bottom: -43px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.page__stat_pt32 {
  padding-top: 32px;
}

.page__stat_sm .sorting {
  position: absolute;
  top: 32px;
  right: 40px;
}

.page__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page__flex .widget:first-child {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 298px;
  margin-right: 32px;
}

.page__flex .widget:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.page__title {
  margin-bottom: 32px;
}

.page__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
}
.page__nav .page__link {
  padding: 7px 18px;
  border-radius: 12px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin: 0 1em;
}

.page__nav .page__link:hover,
.page__nav .page__link.active {
  background: #ff0062;
  color: #ffffff;
}
.page__nav .page__link:hover {
  cursor: pointer;
}

body.dark .page__row_border .page__col:nth-child(2) {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .page__stat {
  background: #242731;
}

body.dark .page__stat:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .page__content_pt64 {
  border-color: rgba(228, 228, 228, 0.1);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 256px;
  height: 100vh;
  padding: 140px 0 72px;
  background: #ffffff;
  border-right: 1px solid #e4e4e4;
}

.sidebar__wrapper {
  max-height: 100%;
  padding: 0 20px 30px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.sidebar__wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar__inner {
  width: 215px;
  overflow: hidden;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
  transition: width 0.25s;
}

.sidebar__top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 140px;
}

.sidebar__logo {
  font-size: 0;
}

.sidebar__logo .sidebar__pic {
  width: 176px;
}

.sidebar__logo .sidebar__pic_white {
  display: none;
}

.sidebar__close {
  position: relative;
  top: -3px;
  display: none;
  margin-right: 25px;
  font-size: 0;
}

.sidebar__close .icon {
  font-size: 20px;
  fill: #11142d;
}

.sidebar__wrapper .sidebar__logo {
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0;
  overflow: hidden;
  margin: 0 -20px;
}

.sidebar__burger {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 96px;
  height: 96px;
  padding: 20px;
  border-bottom: 1px solid transparent;
  font-size: 0;
}

.sidebar__burger:before,
.sidebar__burger:after {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1b1d21;
}

.sidebar__list {
  margin-bottom: 20px;
}

.sidebar__group:not(:last-child) {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 30px;
}

.sidebar__group:not(:last-child):before {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  height: 1px;
  background: #f0f3f6;
}

.sidebar__caption {
  margin-bottom: 16px;
  padding-left: 20px;
  color: #808191;
}

.sidebar__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-radius: 12px;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.sidebar__item:hover {
  color: #ff0062;
}

.sidebar__item.active {
  background: #ff0062;
  color: #ffffff;
}

.sidebar__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  font-size: 0;
}

.sidebar__icon .icon {
  font-size: 21px;
  fill: #1b1d21;
  opacity: 0.4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.sidebar__icon .icon-message {
  font-size: 19px;
}

.sidebar__item:hover .icon {
  fill: #ff0062;
  opacity: 1;
}

.sidebar__item.active .icon {
  opacity: 1;
  fill: #ffffff;
}

.sidebar__item .sidebar__text {
  margin-right: auto;
}

.sidebar__counter {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: 24px;
  margin-left: 10px;
  padding: 0 3px;
  border-radius: 12px;
  background: #ff754c;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff;
}

.sidebar__profile {
  margin-top: 25px;
}

.sidebar__details {
  position: relative;
  display: none;
  padding: 20px 10px 33px;
  margin-bottom: -15px;
  background: #f7f7f7;
  border-radius: 12px;
  -webkit-transition: padding 0.25s, background 0.25s;
  -o-transition: padding 0.25s, background 0.25s;
  transition: padding 0.25s, background 0.25s;
}

.sidebar__details:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 72px;
  height: 4px;
  border-radius: 2px;
  background: #e4e4e4;
}

.sidebar__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.sidebar__link:hover {
  background: #ffffff;
  -webkit-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.04);
}

.sidebar__link .sidebar__icon {
  width: 50px;
  margin: 0;
  padding-right: 6px;
  text-align: center;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.sidebar__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50px;
  height: 56px;
  padding-right: 5px;
}

.sidebar__img .icon {
  font-size: 19px;
}

.sidebar__user {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 20px;
  height: 62px;
  border-radius: 12px;
  color: #11142d;
  cursor: pointer;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.sidebar__user:hover,
.sidebar__user.active {
  background: #3f8cff;
  color: #ffffff;
}

.sidebar__user:hover .sidebar__status,
.sidebar__user.active .sidebar__status {
  color: #ffffff;
}

.sidebar__user:hover .icon,
.sidebar__user.active .icon {
  fill: #ffffff;
}

.sidebar__ava {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
}

.sidebar__ava .sidebar__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.sidebar__arrow {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.sidebar__arrow .icon {
  font-size: 16px;
  fill: #11142d;
}

.sidebar__desc {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 64px);
  flex: 0 0 calc(100% - 64px);
  width: calc(100% - 64px);
  padding: 0 5px 0 16px;
}

.sidebar__man {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
}

.sidebar__status {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808191;
}

.sidebar__bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  flex-flow: row wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 8px 0 40px;
  -webkit-box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.05);
}

.sidebar .switch {
  margin-right: auto;
}

.sidebar__banner {
  position: relative;
  margin-top: 50px;
  padding: 8px 8px 20px;
  border-radius: 16px;
  background: #cfc8ff;
  text-align: center;
}

.sidebar__banner:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
  border-radius: 12px;
  background: rgba(207, 200, 255, 0.5);
}

.sidebar__banner:not(:last-child) {
  margin-bottom: 40px;
}

.sidebar__preview {
  margin-bottom: 6px;
  font-size: 0;
}

.sidebar__banner .sidebar__btn {
  min-width: 171px;
  color: #5f75ee;
}

.sidebar__banner .sidebar__btn:hover {
  color: #ff0062;
}

.sidebar.active .sidebar__top {
  padding: 0;
}

.sidebar.active .sidebar__icon {
  margin-right: 21px;
}

.sidebar.active .sidebar__wrapper .sidebar__logo {
  height: 80px;
}

.sidebar.active .sidebar__details {
  padding: 5px 0 17px;
  background: none;
}

.sidebar.active .sidebar__link .sidebar__icon {
  width: 56px;
  padding: 0;
}

body.dark .sidebar {
  background: #242731;
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .sidebar__pic_black {
  display: none;
}

body.dark .sidebar__pic_white {
  display: inline-block;
}

body.dark .sidebar__icon .icon {
  fill: #ffffff;
}

body.dark .sidebar__item:hover {
  color: #ffffff;
}

body.dark .sidebar__group:before {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .sidebar__details {
  background: rgba(228, 228, 228, 0.04);
}

body.dark .sidebar__details:before {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .sidebar__link:hover {
  background: rgba(228, 228, 228, 0.04);
  color: #ffffff;
}

body.dark .sidebar__link:hover .icon {
  fill: #ffffff;
  opacity: 1;
}

body.dark .sidebar__man {
  color: #ffffff;
}

body.dark .sidebar__arrow .icon {
  fill: #ffffff;
}

body.dark .sidebar__bottom {
  -webkit-box-shadow: inset 0px 1px 0px rgba(228, 228, 228, 0.1);
  box-shadow: inset 0px 1px 0px rgba(228, 228, 228, 0.1);
}

body.dark .sidebar__burger:before,
body.dark .sidebar__burger:after {
  background: #ffffff;
}

body.dark .sidebar__close .icon {
  fill: #ffffff;
}

body.dark .sidebar__btn.btn_white {
  background: #ff0062;
  color: #ffffff;
}

body.dark .sidebar__btn.btn_white:hover {
  background: #1b1d21;
}

body.dark .sidebar.active .sidebar__burger {
  border-color: rgba(228, 228, 228, 0.1);
}

.switch {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.switch__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch__in {
  display: inline-block;
}

.switch__box {
  position: relative;
  display: block;
  width: 64px;
  height: 24px;
  background: rgba(228, 228, 228, 0.5);
  border-radius: 16px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.switch__box:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: #3f8cff;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.switch__icon {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
}

.switch__icon .icon {
  font-size: 24px;
  fill: #3f8cff;
}

.switch__icon .icon-moon {
  display: none;
}

.switch__input:checked + .switch__flex .switch__box:before {
  -webkit-transform: translate(24px, -50%);
  -ms-transform: translate(24px, -50%);
  transform: translate(24px, -50%);
}

.switch_theme .switch__in {
  padding-left: 56px;
}

body.dark .switch_theme .switch__input + .switch__in .switch__box {
  background: #12141b;
}

body.dark .switch_theme .switch__input + .switch__in .switch__box:before {
  width: 4px;
  height: 16px;
  border-width: 2px;
  border-radius: 2px;
  -webkit-transform: translate(48px, -50%);
  -ms-transform: translate(48px, -50%);
  transform: translate(48px, -50%);
  border-color: #3f8cff;
}

body.dark .switch_theme .switch__input + .switch__in .icon-moon {
  display: inline-block;
}

body.dark .switch_theme .switch__input + .switch__in .icon-sun {
  display: none;
}

.slider {
  margin-bottom: 56px;
}

.slider__container {
  position: relative;
  background: #ff0062;
  border-radius: 24px;
}

.slider__container:before,
.slider__container:after {
  content: "";
  position: absolute;
  background: #cfc8ff;
  border-radius: 24px;
}

.slider__container:before {
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: -8px;
  z-index: -1;
}

.slider__container:after {
  top: 16px;
  left: 20px;
  right: 20px;
  bottom: -16px;
  z-index: -2;
}

.slider__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 42px 0 56px 32px;
  color: #ffffff;
}

.slider__details {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 185px;
  padding-top: 13px;
}

.slider__preview {
  margin: 0 auto;
  font-size: 0;
}

.slider__pic {
  max-width: 214px;
  max-height: 214px;
}

.slider__title {
  margin-bottom: 14px;
}

.slider__text {
  margin-bottom: 14px;
}

.slider__close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.slider__close .icon {
  font-size: 12px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.slider__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.slider .owl-dots {
  position: absolute;
  left: 217px;
  right: 0;
  bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider .owl-carousel button.owl-dot {
  position: relative;
  width: 12px;
  height: 12px;
}

.slider .owl-carousel button.owl-dot:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.slider .owl-carousel button.owl-dot:hover:before,
.slider .owl-carousel button.owl-dot.active:before {
  -webkit-box-shadow: 0 0 0 2px #a0d7e7;
  box-shadow: 0 0 0 2px #a0d7e7;
}

.slider .owl-carousel button.owl-dot:not(:last-child) {
  margin-right: 10px;
}

body.dark .slider__close {
  background: #242731;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
}

body.dark .slider__close .icon {
  fill: #ffffff;
}

.btn {
  min-width: 166px;
  height: 56px;
  padding: 0 20px;
  border-radius: 16px/16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 700;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.btn .icon,
.btn__text {
  display: inline-block;
  vertical-align: middle;
}

.btn .icon {
  position: relative;
  top: -2px;
  font-size: 20px;
}

.btn .icon:not(:last-child) {
  margin-right: 10px;
}

.btn_white {
  background: #ffffff;
  color: #1b1d21;
}

.btn_white:hover {
  color: #ff0062;
}

.btn_purple {
  background: #ff0062;
  color: #ffffff;
}

.btn_purple:hover {
  background: #5a49ce;
}

.btn_blue {
  background: #3f8cff;
  color: #ffffff;
}

.btn_blue:hover {
  background: #1b77ff;
}

.btn_blue-dark {
  background: #0049c6;
  color: #ffffff;
}

.btn_blue-dark:hover {
  background: #0056ea;
}

.btn_black {
  background: #1b1d21;
  color: #ffffff;
}

.btn_black .icon {
  fill: #ffffff;
}

.btn_black:hover {
  background: #ff0062;
}

.btn_gray {
  background: #e4e4e4;
  color: #11142d;
}

.btn_gray:hover {
  background: rgba(228, 228, 228, 0.5);
}

.btn_wide {
  min-width: 100%;
}

.btn[href] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

body.dark .btn_white {
  background: #242731;
  color: #ffffff;
}

body.dark .btn_white:hover {
  background: #1b1d21;
}

body.dark .btn_gray {
  background: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .btn_gray:hover {
  opacity: 0.8;
}

.widget {
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.widget__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 88px;
  padding: 0 32px;
}

.widget > .widget__title {
  margin-bottom: 32px;
}

.widget__title {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.33333;
}

.widget__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.widget__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0;
}

.widget__next .icon {
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.widget__next:hover .icon {
  fill: #ff0062;
}

.widget__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget__item:before {
  content: "";
  position: absolute;
  top: -12px;
  left: -12px;
  right: -12px;
  bottom: -12px;
  border-radius: 16px;
  background: #ff0062;
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.widget__item:hover:before {
  opacity: 1;
}

.widget__item:not(:last-child) {
  margin-bottom: 40px;
}

.widget__preview {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 16px;
  overflow: hidden;
  font-size: 0;
}

.widget__pic {
  max-width: 100%;
  max-height: 100%;
}

.widget__details {
  position: relative;
  z-index: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 24px;
}

.widget__item .widget__category {
  position: relative;
  margin-bottom: 3px;
  padding-right: 30px;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.widget__item .widget__category .icon {
  position: absolute;
  top: 50%;
  right: 9px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.widget__info {
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.widget__item:hover .widget__category,
.widget__item:hover .widget__info {
  color: #ffffff;
}

.widget__item:hover .widget__category .icon,
.widget__item:hover .widget__info .icon {
  fill: #ffffff;
}

.widget__wrap {
  padding: 24px;
  border-radius: 16px;
  background: #ffffff;
}

.widget__chart_earning {
  height: 205px;
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: 15px;
}

.widget__chart_impressions {
  height: 320px;
  margin-left: -20px;
}

.widget__chart_users {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 120px;
  height: 80px;
  margin-top: -5px;
}

.widget__chart_earning-month {
  max-width: 120px;
  height: 120px;
  margin: 0 auto;
}

.widget__chart_items {
  height: 240px;
}

.widget__col:first-child {
  margin-bottom: 32px;
}

.widget__counter {
  margin-bottom: 12px;
}

.widget__foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}

.widget__foot .widget__link {
  color: #5f75ee;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.widget__foot .widget__link:hover {
  color: #ff0062;
}

.widget__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.widget__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.widget__color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #808191;
}

.widget__color:not(:last-child) {
  margin-right: 24px;
}

.widget__bg {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  margin-right: 8px;
}

.widget__text_mb32 {
  margin-bottom: 32px;
}

.widget__text_mb40 {
  margin-bottom: 40px;
}

.widget_shadow {
  position: relative;
}

.widget_shadow:before {
  content: "";
  position: absolute;
  top: 22px;
  left: 18px;
  right: 18px;
  bottom: -40px;
  z-index: -2;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.widget_chart {
  padding: 32px 8px 8px;
}

.widget_chart .widget__title {
  padding: 0 24px;
}

.widget_pink {
  background: #ffebf6;
}

.widget_purple {
  background: #ff0062;
}

.widget_stat {
  position: relative;
  text-align: center;
}

.widget_stat:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.widget_stat .widget__title {
  margin-bottom: 12px;
}

.widget_stat > .widget__text {
  max-width: 185px;
  margin: 0 auto 18px;
  color: #808191;
}

.widget_stat .widget__btn.btn_white {
  -webkit-box-shadow: 0 10px 20px rgba(227, 230, 236, 0.6);
  box-shadow: 0 10px 20px rgba(227, 230, 236, 0.6);
  color: #5f75ee;
}

.widget_stat .widget__btn.btn_white:hover {
  color: #1b1d21;
}

.widget_empty {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.widget_stat.widget_empty:before {
  display: none;
}

.widget_p0 {
  padding: 0;
}

.widget_pt0 {
  padding-top: 0;
}

.widget_pb60 {
  padding-bottom: 58px;
}

.widget_users .widget__flex {
  padding: 26px 32px 40px;
  -webkit-box-shadow: inset 0px -1px 0px #e4e4e4;
  box-shadow: inset 0px -1px 0px #e4e4e4;
}

.widget_users .widget__flex:first-child {
  padding-top: 0;
}

.widget_users .widget__desc {
  margin-right: auto;
}

.widget_users .widget__category {
  line-height: 1.3;
  color: #808191;
}

.widget_users .widget__percent {
  font-weight: 600;
  line-height: 1.3;
  color: #7fba7a;
}

.widget_after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  bottom: -8px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.5);
}

body.dark .widget {
  background: #242731;
}

body.dark .widget__item .widget__category {
  color: #ffffff;
}

body.dark .widget__item .widget__category .icon {
  fill: #ffffff;
}

body.dark .widget__wrap {
  background: #242731;
}

body.dark .widget__next .icon {
  fill: #ffffff;
}

body.dark .widget_shadow:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .widget_pink {
  background: #3f8cff;
}

body.dark .widget_stat:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .widget_stat .widget__btn.btn_white {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #1f2128;
}

body.dark .widget_stat .widget__btn.btn_white:hover {
  color: #ffffff;
}

body.dark .widget_empty {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body.dark .widget_users .widget__flex {
  -webkit-box-shadow: inset 0px -1px 0px rgba(228, 228, 228, 0.1);
  box-shadow: inset 0px -1px 0px rgba(228, 228, 228, 0.1);
}

body.dark .widget_purple {
  background: #ff0062;
}

body.dark .widget_after:after {
  background: rgba(36, 39, 49, 0.5);
}

.header {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 298px;
  margin-left: auto;
}

.header .search {
  width: 214px;
  margin-left: -8px;
  margin-right: auto;
}

.header__user {
  display: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-size: 0;
}

.header__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.header__burger {
  display: none;
  width: 32px;
  height: 40px;
  margin-right: auto;
  font-size: 0;
}

.header__burger:before,
.header__burger:after {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1b1d21;
}

.header__search {
  display: none;
  width: 40px;
  height: 40px;
  margin-right: 25px;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
  box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
  font-size: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.header__search .icon {
  font-size: 20px;
  fill: #1b1d21;
}

.header__search.active {
  background: #ff0062;
}

.header__search.active .icon {
  fill: #ffffff;
}

body.dark .header__burger:before,
body.dark .header__burger:after {
  background: #ffffff;
}

body.dark .header__search {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

body.dark .header__search .icon {
  fill: #ffffff;
}

.search {
  position: relative;
  z-index: 3;
}

.search__field {
  position: relative;
  z-index: 2;
}

.search__input {
  width: 100%;
  height: 48px;
  padding: 0 10px 0 44px;
  border-radius: 12px;
  background: transparent;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #11142d;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.search__input::-webkit-input-placeholder {
  color: #808191;
}

.search__input::-ms-input-placeholder {
  color: #808191;
}

.search__input::placeholder {
  color: #808191;
}

.search__toggle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 42px;
  font-size: 0;
}

.search__toggle .icon {
  fill: #1b1d21;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.search__toggle .icon-search {
  font-size: 20px;
}

.search__toggle .icon-close {
  display: none;
  font-size: 16px;
}

.search__toggle:hover .icon {
  fill: #ff0062;
}

.search__result {
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  padding: 70px 16px 24px;
  border-radius: 12px;
  background: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(227, 230, 236, 0.5);
  box-shadow: 0 10px 30px rgba(227, 230, 236, 0.5);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.search__info {
  color: #808191;
}

.search__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 0;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.search__item:hover {
  color: #ff0062;
}

.search__item:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.search__preview {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 12px;
  overflow: hidden;
  font-size: 0;
}

.search__pic {
  max-width: 100%;
  max-height: 100%;
}

.search__text {
  position: relative;
  padding-right: 30px;
  line-height: 1.42857;
  font-weight: 600;
}

.search__text .icon {
  position: absolute;
  top: 5px;
  right: 9px;
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.search__item:hover .icon {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
  fill: #ff0062;
}

.search__btn {
  height: 40px;
}

.search.active .search__input {
  background: rgba(228, 228, 228, 0.3);
}

.search.active .search__toggle .icon-search {
  display: none;
}

.search.active .search__toggle .icon-close {
  display: inline-block;
}

.search.active .search__result {
  visibility: visible;
  opacity: 1;
}

body.dark .search__toggle .icon {
  fill: #ffffff;
}

body.dark .search__toggle:hover .icon {
  fill: #ff0062;
}

body.dark .search__input {
  color: #ffffff;
}

body.dark .search__result {
  background: #242731;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

body.dark .search__item {
  color: #ffffff;
}

body.dark .search__text .icon {
  fill: #ffffff;
}

body.dark .search__item:not(:last-child) {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .search.active .search__input {
  background: rgba(228, 228, 228, 0.03);
}

.notifications {
  position: relative;
}

.notifications__open {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.notifications__open .icon {
  font-size: 24px;
  fill: #1b1d21;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.notifications__open:hover {
  -webkit-box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
  box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
}

.notifications__counter {
  position: absolute;
  top: 0;
  right: -12px;
  display: inline-block;
  min-width: 24px;
  line-height: 24px;
  border-radius: 50%;
  background: #ff754c;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}

.notifications__wrap {
  position: absolute;
  top: calc(100% + 24px);
  right: -12px;
  width: 360px;
  padding: 32px 8px 36px;
  -webkit-box-shadow: 0 10px 36px rgba(227, 230, 236, 0.6);
  box-shadow: 0 10px 36px rgba(227, 230, 236, 0.6);
  background: #ffffff;
  border-radius: 24px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.notifications__info {
  margin-bottom: 32px;
  padding: 0 24px;
}

.notifications__list {
  margin-bottom: 32px;
}

.notifications__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 28px 16px 20px;
  border-radius: 12px;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.notifications__item:hover {
  background: #ff0062;
}

.notifications__ava {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  font-size: 0;
}

.notifications__ava > .notifications__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.notifications__status {
  position: absolute;
  top: 0;
  right: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 21px;
  height: 21px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  font-size: 0;
}

.notifications__status .notifications__pic {
  max-width: 10px;
}

.notifications__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.notifications__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 6px;
}

.notifications__user {
  margin-right: auto;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.notifications__time,
.notifications__text {
  color: #b2b3bd;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.notifications__time {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 20px;
}

.notifications__project {
  padding-left: 5px;
  color: #5f75ee;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.notifications__item:hover .notifications__user,
.notifications__item:hover .notifications__time,
.notifications__item:hover .notifications__text,
.notifications__item:hover .notifications__project {
  color: #ffffff;
}

.notifications__btns {
  text-align: center;
}

.notifications__btn {
  min-width: 230px;
}

.notifications.active .notifications__open {
  background: #ff0062;
  -webkit-box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
  box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
}

.notifications.active .notifications__open .icon {
  fill: #ffffff;
}

.notifications.active .notifications__wrap {
  visibility: visible;
  opacity: 1;
}

body.dark .notifications__open {
  background: #242731;
}

body.dark .notifications__open .icon {
  fill: #ffffff;
}

body.dark .notifications__open:hover {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

body.dark .notifications__wrap {
  background: #242731;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

body.dark .notifications__user {
  color: #ffffff;
}

body.dark .notifications.active .notifications__open {
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.7);
}

.banner__container {
  position: relative;
  padding: 64px 64px 76px;
  border-radius: 24px;
  background: #ff0062;
  overflow: hidden;
}

.banner__wrap {
  max-width: 400px;
  color: #ffffff;
}

.banner__text {
  margin-bottom: 16px;
}

.banner__btn {
  min-width: 209px;
}

.banner__preview {
  position: absolute;
  top: 40px;
  right: 90px;
  width: 543px;
  font-size: 0;
}

.banner__preview .banner__pic {
  width: 100%;
}

.banner:not(:last-child) {
  margin-bottom: 32px;
}

.sorting__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.sorting__col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}

.sorting__dropdowns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.sorting__dropdowns .dropdown {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}

.sorting__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sorting__search {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.sorting__line .sorting__search {
  margin-right: 45px;
}

.sorting__input {
  width: 100%;
  height: 56px;
  padding: 0 20px 0 55px;
  border: none;
  border-radius: 16px;
  background: rgba(228, 228, 228, 0.2);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #1b1d21;
}

.sorting__input::-webkit-input-placeholder {
  color: #808191;
}

.sorting__input::-ms-input-placeholder {
  color: #808191;
}

.sorting__input::placeholder {
  color: #808191;
}

.sorting__open {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  font-size: 0;
}

.sorting__open .icon {
  font-size: 20px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.sorting__open:hover .icon {
  fill: #ff0062;
}

.sorting__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sorting__action {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
}

.sorting__action .icon {
  font-size: 17px;
  fill: #11142d;
}

.sorting__action .icon-edit {
  font-size: 19px;
}

.sorting__action .icon-box {
  font-size: 15px;
}

.sorting__action:hover {
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
}

.sorting__action:not(:last-child) {
  margin-right: 24px;
}

.sorting:not(:last-child) {
  margin-bottom: 15px;
}

body.dark .sorting__input {
  background: rgba(228, 228, 228, 0.04);
  color: #ffffff;
}

body.dark .sorting__open .icon {
  fill: #ffffff;
}

body.dark .sorting__action {
  background: #242731;
}

body.dark .sorting__action .icon {
  fill: #ffffff;
}

body.dark .sorting__action:hover {
  -webkit-box-shadow: 0 12px 20px rgba(0, 0, 0, 0.25);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.25);
}

.dropdown {
  position: relative;
}

.dropdown__head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 56px;
  padding: 0 46px 0 21px;
  border: 2px solid transparent;
  background: rgba(228, 228, 228, 0.3);
  border-radius: 16px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.dropdown__head:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 14px;
  height: 8px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='%231b1d21' d='M.293.293A1 1 0 0 1 1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-6 6a1 1 0 0 1-1.32.083l-.094-.083-6-6a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    no-repeat 50% 50%/100% auto;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.dropdown__body {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  z-index: 20;
  width: 310px;
  padding: 32px;
  border: 1px solid #e4e4e4;
  background: #ffffff;
  border-radius: 24px;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0 20px 16px rgba(227, 230, 236, 0.6);
  box-shadow: 0 20px 16px rgba(227, 230, 236, 0.6);
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.dropdown__item {
  display: block;
}

.dropdown__item:hover {
  cursor: pointer;
}

.dropdown__item:not(:last-child) {
  margin-bottom: 24px;
}

.dropdown__title {
  position: relative;
  margin-bottom: 3px;
  padding-right: 30px;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.dropdown__title:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 8px;
  width: 6px;
  height: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10'%3E%3Cpath fill='%2311142d' d='M.293.293A1 1 0 0 1 1.613.21l.094.083 4 4a1 1 0 0 1 .083 1.32l-.083.094-4 4A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707A1 1 0 0 1 .21.387L.293.293z'/%3E%3C/svg%3E")
    no-repeat 50% 50%/100% auto;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.dropdown__info {
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.dropdown__item:hover .dropdown__title:before {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

.dropdown__item:hover .dropdown__title,
.dropdown__item:hover .dropdown__info {
  color: #ff0062;
}

.dropdown.active .dropdown__head {
  border-color: #5f75ee;
  background: #ffffff;
  color: #11142d;
}

.dropdown.active .dropdown__head:before {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.dropdown.active .dropdown__body {
  visibility: visible;
  opacity: 1;
}

body.dark .dropdown__head {
  background: rgba(228, 228, 228, 0.04);
}

body.dark .dropdown__head:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='%23ffffff' d='M.293.293A1 1 0 0 1 1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-6 6a1 1 0 0 1-1.32.083l-.094-.083-6-6a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
}

body.dark .dropdown__body {
  border-color: rgba(228, 228, 228, 0.1);
  background: #242731;
  -webkit-box-shadow: 0 20px 16px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 16px rgba(0, 0, 0, 0.5);
}

body.dark .dropdown__title {
  color: #ffffff;
}

body.dark .dropdown__title:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10'%3E%3Cpath fill='%23ffffff' d='M.293.293A1 1 0 0 1 1.613.21l.094.083 4 4a1 1 0 0 1 .083 1.32l-.083.094-4 4A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707A1 1 0 0 1 .21.387L.293.293z'/%3E%3C/svg%3E");
}

body.dark .dropdown.active .dropdown__head {
  background: rgba(228, 228, 228, 0.04);
  color: #ffffff;
}

.products > .products__title {
  margin-bottom: 16px;
  padding: 0 24px;
}

.products > .products__info {
  margin-bottom: 20px;
  color: #808191;
  padding: 0 24px;
}

.products__table {
  display: table;
  width: 100%;
}

.products__row {
  display: table-row;
}

.products__row_head .products__cell {
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 13px;
  line-height: 1.38462;
  font-weight: 500;
  color: #b2b3bd;
}

.products__cell {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e4e4e4;
}

.products__cell:first-child {
  width: auto;
  /* padding: 0; */
  /* font-size: 0; */
}

.products__item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #11142d;
}

.products__preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 96px;
  height: 72px;
  border-radius: 8px;
  background: #a0d7e7;
  font-size: 0;
}

.products__preview:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 7px;
  right: 7px;
  height: 8px;
  border-radius: 0 0 8px 8px;
  background: #e7faff;
}

.products__pic {
  max-height: 100%;
}

.products__details {
  padding-left: 24px;
}

.products__cell .products__title {
  margin-bottom: 9px;
}

.products__color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.products__bg {
  width: 20px;
  height: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 4px;
}

.products__rating {
  color: #808191;
}

.products__color .products__text {
  margin-left: 8px;
}

.products__more {
  padding-top: 40px;
  text-align: center;
}

.products__cost {
  position: relative;
  cursor: pointer;
}

.products__cost .products__tooltip {
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 160px;
  padding: 32px 26px;
  background: #ffffff;
  border-radius: 24px;
  -webkit-box-shadow: 0 20px 30px rgba(227, 230, 236, 0.9);
  box-shadow: 0 20px 30px rgba(227, 230, 236, 0.9);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.products__cost:hover .products__tooltip {
  visibility: visible;
  opacity: 1;
}

.products__sale {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  color: #808191;
}

.products__sale .products__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 6px;
}

.products__sale .products__pic {
  max-width: 8px;
}

.products__progress {
  position: relative;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background: #e4e4e4;
}

.products__progress span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 1px;
  background: #ff0062;
}

.products__price:not(:last-child) {
  margin-bottom: 10px;
}

.products__transaction {
  color: #808191;
}

.products__status {
  display: inline-block;
  padding: 0 16px;
  border-radius: 4px;
  color: #ffffff;
}

.products__body {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 24px 24px;
  padding: 4px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
}

.products__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.products_main .products__money {
  color: #5f75ee;
}

.products_main .products__cell:nth-child(2) {
  font-size: 0;
}

.products_main .products__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  padding-right: 8px;
}

.products_main .products__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 44%;
  flex: 0 0 44%;
  padding-right: 8px;
}

.products_main .products__col:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26%;
  flex: 0 0 26%;
}

.products_history .products__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 42%;
  flex: 0 0 42%;
  padding-right: 8px;
}

.products_history .products__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  padding-right: 8px;
}

.products_history .products__col:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26%;
  flex: 0 0 26%;
}

.products_statement .products__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 42%;
  flex: 0 0 42%;
  padding-right: 8px;
}

.products_statement .products__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
  padding-right: 8px;
}

.products_statement .products__col:nth-child(3) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26%;
  flex: 0 0 26%;
}

body.dark .products__cell {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .products__item {
  color: #ffffff;
}

body.dark .products__body {
  border-color: rgba(228, 228, 228, 0.1);
}

.checkbox {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.checkbox__in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox__tick {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #e4e4e4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.checkbox__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 9px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z' fill='white'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.checkbox__text {
  margin-left: 8px;
  padding-top: 2px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.3;
  color: #808191;
}

.checkbox:hover .checkbox__tick {
  border-color: #ff0062;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: #ff0062;
  border-color: #ff0062;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

.checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #11142d;
}

body.dark .checkbox__tick {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .checkbox__input:checked + .checkbox__in .checkbox__text {
  color: #ffffff;
}

.radio {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.radio__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.radio__in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.radio__tick {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #e4e4e4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.radio__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.radio__text {
  margin-left: 8px;
  padding-top: 3px;
  font-size: 13px;
  line-height: 1.2;
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.radio:hover .radio__tick {
  background: #ff0062;
}

.radio__input:checked + .radio__in .radio__tick {
  background: #ff0062;
}

.radio__input:checked + .radio__in .radio__tick:before {
  opacity: 1;
}

.radio__input:checked + .radio__in .radio__text {
  color: #11142d;
}

body.dark .radio__tick {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .radio__input:checked + .radio__in .radio__text {
  color: #ffffff;
}

.details__container {
  position: relative;
  padding: 32px 32px 38px;
  background: #ffffff;
  border-radius: 24px;
}

.details__container:before {
  content: "";
  position: absolute;
  top: 28px;
  left: 18px;
  right: 18px;
  bottom: -28px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.details__title {
  margin-bottom: 40px;
}

.details__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.details__col:first-child {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 145px;
  padding-right: 16px;
}

.details__col:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.details__top {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e4e4e4;
}

.details__number {
  margin-bottom: 16px;
}

.details__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5f75ee;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.details__line:hover {
  color: #ff0062;
}

.details__preview {
  font-size: 0;
}

.details__line .details__preview {
  width: 32px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 16px;
}

.details__statistics {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.details__status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.details__status .details__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  border-radius: 50%;
  font-size: 0;
}

.details__status .details__icon .icon {
  font-size: 6px;
  fill: #ffffff;
}

.details__box {
  position: relative;
  padding: 5px 5px 13px;
  border-radius: 16px;
  background: #3f8cff;
}

.details__chart_activity {
  height: 25px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 13px;
}

.details__chart_counter {
  height: 200px;
}

.details__chart_counter .apexcharts-tooltip.apexcharts-theme-light {
  background: #1b1d21;
  border: none;
  color: #ffffff;
}

.details__chart_report {
  height: 215px;
}

.details__chart_report__xl {
  height: auto;
}

.details__info {
  color: #b2b3bd;
}

.details__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}

.details__item {
  padding: 24px;
}

.details__item:not(:last-child) {
  border-right: 1px solid #e4e4e4;
}

.details__list_three .details__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333%;
  flex: 0 0 33.333%;
  width: 33.333%;
}

.details__list_four .details__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
}

.details__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
}

.details__head .details__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border-radius: 6px;
  font-size: 0;
}

.details__head .details__pic {
  max-width: 8px;
}

.details__indicator {
  position: relative;
  height: 2px;
  border-radius: 1px;
  background: #e4e4e4;
}

.details__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.details__remove {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
}

.details__remove .icon {
  font-size: 10px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.details__remove:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.details .dropdown {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 200px;
}

.details:not(:last-child) {
  margin-bottom: 28px;
}

.details_statements:not(:last-child) {
  margin-bottom: 68px;
}

.details_statements .details__col:first-child {
  width: 170px;
  padding-right: 25px;
}

.details_statements .details__counter {
  margin-bottom: 10px;
}

.details_statements .details__line .details__info {
  color: #5f75ee;
}

body.dark .details__container {
  background: #242731;
}

body.dark .details__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .details__remove {
  background: #242731;
}

body.dark .details__remove .icon {
  fill: #ffffff;
}

body.dark .details__list {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .details__top {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .details__item {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .details__indicator {
  background: rgba(228, 228, 228, 0.1);
}

.quality__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quality__item_chart {
  position: relative;
  padding-right: 60px;
}

.quality__item[href] {
  color: #11142d;
}

.quality__item:not(:last-child) {
  margin-bottom: 48px;
}

.quality__preview {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 14px;
  border-radius: 12px;
  font-size: 0;
}

.quality__preview .quality__pic {
  max-height: 100%;
}

.quality__chart {
  position: absolute;
  top: 73%;
  right: -32px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 110px;
  height: 110px;
}

.quality__details {
  position: relative;
  z-index: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.quality__percent {
  position: absolute;
  top: 39%;
  left: 51%;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.quality__info {
  margin-top: 5px;
  color: #808191;
}

.quality__btn {
  margin-top: 40px;
}

.quality__btn_mt30 {
  margin-top: 30px;
}

.quality__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.quality__line .quality__price {
  margin-left: auto;
  padding-left: 10px;
  color: #7fba7a;
}

.quality__category {
  position: relative;
  padding-right: 20px;
}

.quality__category .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 10px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.quality__money {
  display: inline-block;
  margin-top: 8px;
  padding: 3px 10px;
  background: #ff0062;
  border-radius: 8px;
  color: #ffffff;
}

.quality_big .quality__list {
  margin: -8px -16px;
}

.quality_big .quality__item {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border-radius: 12px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.quality_big .quality__item:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  height: 0;
  border-radius: 0 0 8px 8px;
  background: #cfc8ff;
  -webkit-transition: height 0.25s;
  -o-transition: height 0.25s;
  transition: height 0.25s;
}

.quality_big .quality__item:hover {
  background: #ff0062;
  color: #ffffff;
}

.quality_big .quality__item:hover:before {
  height: 6px;
}

.quality_big .quality__item:not(:last-child) {
  margin-bottom: 24px;
}

.quality_big .quality__item:hover .quality__category {
  color: #ffffff;
}

.quality_big .quality__item:hover .quality__category .icon {
  fill: #ffffff;
}

.quality_big .quality__item:hover .quality__info {
  color: #ffffff;
}

.quality_big .quality__preview {
  position: relative;
  width: 88px;
  height: 80px;
  margin-right: 24px;
}

.quality_big .quality__preview:before {
  content: "";
  position: absolute;
}

body.dark .quality__item {
  color: #ffffff;
}

body.dark .quality__category .icon {
  fill: #ffffff;
}

.goal__container {
  min-height: 220px;
  margin: 0 -16px 26px;
}

.goal__preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 220px;
  font-size: 0;
}

.goal__pic {
  position: relative;
  z-index: 2;
  max-height: 100%;
}

.goal__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  border-radius: 16px;
}

.goal__item:not(:last-child) {
  margin-bottom: 32px;
}

.goal__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 13px;
}

.goal__percent {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 15px;
}

.goal__indicator {
  position: relative;
  height: 8px;
  background: #e4e4e4;
  border-radius: 4px;
}

.goal__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
}

.goal .owl-carousel .owl-item img {
  width: auto;
}

.goal .owl-nav {
  position: absolute;
  top: 0;
  left: 16px;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.goal .owl-carousel .owl-nav button.owl-prev,
.goal .owl-carousel .owl-nav button.owl-next {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px #e3e6ec;
  box-shadow: 0 5px 10px #e3e6ec;
  font-size: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.goal .owl-carousel .owl-nav button.owl-prev .icon,
.goal .owl-carousel .owl-nav button.owl-next .icon {
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.goal .owl-carousel .owl-nav button.owl-prev:hover,
.goal .owl-carousel .owl-nav button.owl-next:hover {
  background: #ff0062;
}

.goal .owl-carousel .owl-nav button.owl-prev:hover .icon,
.goal .owl-carousel .owl-nav button.owl-next:hover .icon {
  fill: #ffffff;
}

.goal .owl-carousel .owl-nav button.owl-prev {
  margin-right: 8px;
}

body.dark .goal__indicator {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .goal .owl-carousel .owl-nav button.owl-prev,
body.dark .goal .owl-carousel .owl-nav button.owl-next {
  background: #242731;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

body.dark .goal .owl-carousel .owl-nav button.owl-prev .icon,
body.dark .goal .owl-carousel .owl-nav button.owl-next .icon {
  fill: #ffffff;
}

body.dark .goal .owl-carousel .owl-nav button.owl-prev:hover,
body.dark .goal .owl-carousel .owl-nav button.owl-next:hover {
  background: #ff0062;
}

.schedules__container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 32px 32px 16px;
  border-radius: 24px;
  background: #ffffff;
}

.schedules__container:before {
  content: "";
  position: absolute;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: -35px;
  z-index: -5;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.schedules__col:first-child {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 310px;
}

.schedules__col:first-child .schedules__title {
  display: none;
}

.schedules__col:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 16px;
}

.schedules__list {
  margin-top: -8px;
}

.schedules__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 12px;
  background: transparent;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.schedules__item:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  height: 6px;
  border-radius: 0 0 6px 6px;
  background: #cfc8ff;
  opacity: 0;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.schedules__item:hover {
  color: #ff0062;
}

.schedules__item:hover .icon {
  fill: #ff0062;
}

.schedules__item.active {
  background: #ff0062;
  color: #ffffff;
}

.schedules__item.active:before {
  opacity: 1;
}

.schedules__item:not(:last-child) {
  margin-bottom: 24px;
}

.schedules__date {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 88px;
  height: 80px;
  margin-right: 24px;
  padding-top: 7px;
  border-radius: 12px;
}

.schedules__date:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 9px;
  right: 9px;
  height: 7px;
  border-radius: 0 0 10px 10px;
}

.schedules__date.bg-pink-light:before {
  background: rgba(255, 235, 246, 0.5);
}

.schedules__date.bg-purple-light:before {
  background: rgba(207, 200, 255, 0.5);
}

.schedules__date.bg-blue-light:before {
  background: rgba(160, 215, 231, 0.5);
}

.schedules__date.bg-yellow-light:before {
  background: rgba(255, 226, 172, 0.5);
}

.schedules__name {
  line-height: 1;
  color: #808191;
}

.schedules__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.schedules__category {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  padding-right: 35px;
}

.schedules__category .icon {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 10px;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.schedules__info {
  margin-bottom: 8px;
  color: #808191;
}

.schedules__time {
  display: inline-block;
  padding: 3px 10px;
  background: #ff0062;
  border-radius: 8px;
  color: #ffffff;
}

.schedules__item.active .schedules__name,
.schedules__item.active .schedules__info {
  color: #ffffff;
}

.schedules__item.active .schedules__category .icon {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
  fill: #ffffff;
}

.schedules__item.active .schedules__date {
  background: transparent;
}

.schedules__item.active .schedules__date:before {
  background: transparent;
}

.schedules__item.active .schedules__time {
  background: #ffebf6;
  color: #11142d;
}

.schedules__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}

.schedules__arrows {
  display: none;
  margin-left: auto;
  margin-right: -16px;
}

.schedules__arrow {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  background: #ffffff;
  font-size: 0;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.schedules__arrow .icon {
  font-size: 10px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.schedules__arrow:hover {
  background: #ff0062;
}

.schedules__arrow:hover .icon {
  fill: #ffffff;
}

.schedules__arrow:not(:last-child) {
  margin-right: 8px;
}

.schedules__col > .schedules__title {
  margin-bottom: 32px;
}

.schedules__btn {
  display: none;
}

.schedules__graphic {
  position: relative;
  max-width: 586px;
  border-radius: 16px;
  border: 1px solid #e4e4e4;
}

.schedules__graphic:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 14px;
  right: 14px;
  height: 7px;
  border-radius: 0 0 7px 7px;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: #e4e4e4;
}

.schedules__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 68px;
  border-bottom: 1px solid #e4e4e4;
}

.schedules__option {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 138px;
  padding-left: 32px;
  font-size: 13px;
  font-weight: 600;
  color: #808191;
}

.schedules__week {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.schedules__cell {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666%;
  flex: 0 0 16.666%;
  width: 16.666%;
}

.schedules__day {
  position: relative;
  display: inline-block;
  min-width: 32px;
  margin-left: -16px;
  background: transparent;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.schedules__day:before {
  content: "";
  position: absolute;
  top: 6px;
  right: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.schedules__day.active {
  background: #ff754c;
  color: #ffffff;
}

.schedules__day.green:before {
  background: #7fba7a;
}

.schedules__day.purple:before {
  background: #ff0062;
}

.schedules__day.gray:before {
  background: #e4e4e4;
}

.schedules__body {
  position: relative;
  padding: 26px 0 22px;
}

.schedules__grid {
  position: absolute;
  top: 0;
  left: 138px;
  right: 0;
  bottom: 0;
}

.schedules__line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #e4e4e4;
  pointer-events: none;
}

.schedules__line:first-child {
  left: 8.33%;
}

.schedules__line:nth-child(2) {
  left: 25%;
}

.schedules__line:nth-child(3) {
  left: 41.66%;
}

.schedules__line:nth-child(4) {
  left: 58.33%;
}

.schedules__line:nth-child(5) {
  left: 75%;
}

.schedules__row {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.schedules__row:not(:last-child) {
  margin-bottom: 18px;
}

.schedules__task {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  padding: 0 2px;
  border-radius: 8px;
}

.schedules__task .schedules__text {
  padding: 0 15px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}

.schedules__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  padding: 0 16px;
}

.schedules__status {
  position: relative;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  color: #808191;
}

.schedules__status:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.schedules__status.green:before {
  background: #7fba7a;
}

.schedules__status.purple:before {
  background: #ff0062;
}

.schedules__status.gray:before {
  background: #e4e4e4;
}

.schedules__status:not(:last-child) {
  margin-right: 120px;
}

.schedules:not(:last-child) {
  margin-bottom: 36px;
}

body.dark .schedules__container {
  background: #242731;
}

body.dark .schedules__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .schedules__item {
  color: #ffffff;
}

body.dark .schedules__category .icon {
  fill: #ffffff;
}

body.dark .schedules__name,
body.dark .schedules__number {
  color: #5f75ee;
}

body.dark .schedules__item:hover {
  color: #ff0062;
}

body.dark .schedules__item:hover .icon {
  fill: #ff0062;
}

body.dark .schedules__item.active,
body.dark .schedules__item.active .schedules__number {
  color: #ffffff;
}

body.dark .schedules__item.active .icon {
  fill: #ffffff;
}

body.dark .schedules__graphic {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__graphic:before {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__head {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__day.gray:before,
body.dark .schedules__status.gray:before {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__line {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .schedules__task .schedules__text {
  background: rgba(36, 39, 49, 0.8);
}

body.dark .schedules__arrow {
  background: #242731;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
}

body.dark .schedules__arrow .icon {
  fill: #ffffff;
}

.tabs__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -20px 0 14px -16px;
}

.tabs__col:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 53%;
  flex: 0 0 53%;
  width: 53%;
}

.tabs__col:nth-child(2) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 47%;
  flex: 0 0 47%;
  width: 47%;
  padding-top: 20px;
}

.tabs__list {
  margin-left: -16px;
}

.tabs__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 16px;
  border-radius: 16px;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.tabs__item:hover {
  color: #ff0062;
}

.tabs__item:hover .icon {
  fill: #ff0062;
}

.tabs__item.active {
  background: #ffebf6;
  color: #11142d;
}

.tabs__item.active .icon {
  fill: #11142d;
}

.tabs__preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 88px;
  height: 80px;
  margin-right: 16px;
  border-radius: 12px;
  font-size: 0;
}

.tabs__pic {
  max-height: 100%;
}

.tabs__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.tabs__category {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 3px;
  padding-right: 20px;
}

.tabs__category .icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 10px;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.tabs__info {
  margin-bottom: 8px;
  color: #808191;
}

.tabs__price {
  display: inline-block;
  padding: 3px 10px;
  background: #ff0062;
  border-radius: 8px;
  color: #ffffff;
}

.tabs__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
}

.tabs__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
  padding: 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.tabs__link:hover {
  color: #ff0062;
}

.tabs__link:hover .icon {
  fill: #ff0062;
}

.tabs__link.active {
  background: #ff0062;
  color: #ffffff;
}

.tabs__link.active .icon {
  fill: #ffffff;
}

.tabs__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
  font-size: 0;
}

.tabs__icon .icon {
  font-size: 18px;
  fill: #11142d;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.tabs__chart {
  max-width: 190px;
  height: 140px;
  margin: 0 auto 20px;
}

.tabs__desc {
  text-align: center;
}

.tabs__title {
  margin-bottom: 10px;
}

.tabs__date {
  margin-bottom: 50px;
  color: #808191;
}

.tabs__btn {
  min-width: 180px;
}

body.dark .tabs__item {
  color: #ffffff;
}

body.dark .tabs__item:hover {
  color: #ff0062;
}

body.dark .tabs__item:hover .icon {
  fill: #ff0062;
}

body.dark .tabs__item.active {
  color: #11142d;
}

body.dark .tabs__item.active .tabs__category .icon {
  fill: #11142d;
}

body.dark .tabs__category .icon {
  fill: #ffffff;
}

body.dark .tabs__nav {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .tabs__icon .icon {
  fill: #ffffff;
}

body.dark .tabs__link:hover {
  color: #ffffff;
}

.payment__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment__details {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 225px;
  margin-right: 16px;
  padding: 32px;
  border-radius: 16px;
  -webkit-box-shadow: 0 10px 36px rgba(227, 230, 236, 0.6);
  box-shadow: 0 10px 36px rgba(227, 230, 236, 0.6);
  text-align: center;
}

.payment__preview {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
  border-radius: 24px;
  font-size: 0;
}

.payment__preview .payment__pic {
  max-height: 100%;
}

.payment__pic_white {
  display: none;
}

.payment__mail {
  margin-bottom: 16px;
  color: #7fba7a;
}

.payment__info {
  width: 100%;
  max-width: 140px;
  color: #b2b3bd;
}

.payment__details .payment__info {
  margin: 0 auto 32px;
}

.payment__logo {
  font-size: 0;
}

.payment__details .payment__logo {
  display: inline-block;
}

.payment__logo .payment__pic {
  max-width: 87px;
  max-height: 30px;
}

.payment__variants {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.payment__label {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.payment__label:not(:last-child) {
  margin-bottom: 18px;
}

.payment__radio {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.payment__in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 34px 32px;
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 1px #e3e6ec;
  box-shadow: inset 0 0 0 1px #e3e6ec;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
}

.payment__tick {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16px;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin-right: 36px;
  border-radius: 4px;
  border: 2px solid #e4e4e4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.payment__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 8px;
  height: 7px;
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5.375L1.5 3.375L0 4.875L3.5 8.375L10 1.875L8.5 0.375L3.5 5.375Z' fill='white'/%3E%3C/svg%3E%0A")
    no-repeat 50% 50%/100% auto;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.payment__desc .payment__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
  margin-top: -8px;
}

.payment__desc .payment__info {
  display: block;
  margin-top: 16px;
}

.payment__btn {
  min-width: 132px;
  height: 40px;
  margin-top: 16px;
}

.payment__label:hover .payment__in {
  -webkit-box-shadow: inset 0 0 0 1px #ff0062;
  box-shadow: inset 0 0 0 1px #ff0062;
}

.payment__radio:checked + .payment__in {
  -webkit-box-shadow: inset 0 0 0 2px #ff0062;
  box-shadow: inset 0 0 0 2px #ff0062;
}

.payment__radio:checked + .payment__in .payment__tick {
  background: #ff0062;
  border-color: #ff0062;
}

.payment__radio:checked + .payment__in .payment__tick:before {
  opacity: 1;
}

body.dark .payment__pic_black {
  display: none;
}

body.dark .payment__pic_white {
  display: inline-block;
}

body.dark .payment__details {
  -webkit-box-shadow: 0 10px 36px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 36px rgba(0, 0, 0, 0.35);
}

body.dark .payment__tick {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .payment__in {
  -webkit-box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.1);
  box-shadow: inset 0 0 0 1px rgba(228, 228, 228, 0.1);
}

.inbox__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -18px 0 28px -18px;
}

.inbox__tag {
  margin: 18px 0 0 18px;
  border: 2px solid #ff754c;
  border-radius: 12px;
  padding: 6px 16px;
  line-height: 1.42857;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.inbox__tag:hover,
.inbox__tag.active {
  background: #ff0062;
  border-color: #ff0062;
  color: #ffffff;
}

.inbox__container {
  position: relative;
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.inbox__container:before {
  content: "";
  position: absolute;
  top: 32px;
  left: 18px;
  right: 18px;
  bottom: -32px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.inbox__container > .inbox__title {
  margin-bottom: 16px;
}

.inbox__list {
  margin: 0 -16px;
}

.inbox__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  border-radius: 12px;
  background: transparent;
  color: #808191;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.inbox__ava {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  font-size: 0;
}

.inbox__ava .inbox__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.inbox__details {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 48px);
  flex: 0 0 calc(100% - 48px);
  width: calc(100% - 48px);
  padding-left: 24px;
}

.inbox__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}

.inbox__author {
  color: #5f75ee;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.inbox__time {
  margin-left: 10px;
}

.inbox__item .inbox__title {
  margin-bottom: 18px;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.inbox__text {
  margin-bottom: 16px;
}

.inbox__text p:not(:last-child) {
  margin-bottom: 20px;
}

.inbox__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.inbox__item.active {
  background: #ff0062;
  color: #ffffff;
}

.inbox__item.active .actions__btn {
  border-color: #ffffff;
}

.inbox__item.active .actions__btn .icon {
  fill: #ffffff;
}

.inbox__item.active .inbox__title,
.inbox__item.active .inbox__author {
  color: #ffffff;
}

.inbox__foot {
  padding-top: 12px;
  text-align: center;
}

.inbox__foot .inbox__btn {
  min-width: 180px;
}

.inbox__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
}

.inbox__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: auto;
}

.inbox__btns .inbox__btn {
  min-width: 140px;
}

.inbox__btns .inbox__btn:not(:last-child) {
  margin-right: 16px;
}

.inbox__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  font-size: 0;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
}

.inbox__more .icon {
  font-size: 6px;
  fill: #11142d;
}

.inbox__more:hover {
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
}

.inbox__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inbox__box:not(:last-child) {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e4e4e4;
}

.inbox__box .inbox__author {
  color: #11142d;
}

.inbox__box .inbox__time {
  margin-left: 24px;
  color: #b2b3bd;
}

.inbox__box .inbox__title {
  margin-bottom: 32px;
}

.inbox__box .inbox__text {
  color: #808191;
}

.inbox .files {
  margin-top: 24px;
}

.inbox .sorting {
  margin-bottom: 24px;
}

body.dark .inbox__tag {
  color: #ffffff;
}

body.dark .inbox__container {
  background: #242731;
}

body.dark .inbox__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .inbox__item:hover {
  background: rgba(228, 228, 228, 0.04);
}

body.dark .inbox__item.active {
  background: #ff0062;
}

body.dark .inbox__title {
  color: #ffffff;
}

body.dark .inbox__item.active .inbox__title {
  color: #ffffff;
}

body.dark .inbox__item.active .inbox__author {
  color: #ffffff;
}

body.dark .inbox__more .icon {
  fill: #ffffff;
}

body.dark .inbox__more:hover {
  -webkit-box-shadow: 0 12px 20px rgba(0, 0, 0, 0.35);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.35);
}

body.dark .inbox__box {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .inbox__box .inbox__author {
  color: #ffffff;
}

.actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.actions__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #e4e4e4;
  font-size: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.actions__btn .icon {
  font-size: 12px;
  fill: #1b1d21;
  opacity: 0.4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.actions__btn:hover .icon {
  opacity: 0.75;
}

.actions__btn:not(:last-child) {
  margin-right: 16px;
}

body.dark .actions__btn {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .actions__btn .icon {
  fill: #ffffff;
}

.files__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -8px -4px 0;
}

.files__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 4px;
  border-radius: 16px;
  color: #11142d;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.files__item.purple {
  background: rgba(207, 200, 255, 0.5);
}

.files__item.purple .files__preview {
  background: #cfc8ff;
}

.files__item.blue {
  background: #e7faff;
}

.files__item.blue .files__preview {
  background: #a0d7e7;
}

.files__item:hover {
  opacity: 0.8;
}

.files > .files__item:not(:last-child) {
  margin-bottom: 10px;
}

.files__list .files__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 8px);
  flex: 0 0 calc(50% - 8px);
  width: calc(50% - 8px);
  margin: 4px 4px 0;
}

.files__preview {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  overflow: hidden;
  font-size: 0;
}

.files__pic {
  max-width: 100%;
  max-height: 100%;
}

.files__details {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 48px);
  flex: 0 0 calc(100% - 48px);
  width: calc(100% - 48px);
  padding-left: 16px;
}

.files__title {
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #5f75ee;
}

body.dark .files__item {
  background: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .files__title {
  color: #ffffff;
}

.editor__wrap {
  background: #fafafa;
  border-radius: 12px;
}

.editor__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  border-bottom: 1px solid #e4e4e4;
}

.editor__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -16px auto 0 -16px;
}

.editor__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin: 16px 0 0 16px;
  border-radius: 4px;
  background: #e4e4e4;
  font-size: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.editor__action .icon {
  font-size: 12px;
  fill: #808191;
  -webkit-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
}

.editor__action:hover,
.editor__action.active {
  background: rgba(27, 29, 33, 0.3);
}

.editor__action:hover .icon,
.editor__action.active .icon {
  fill: #ffffff;
}

.editor__add {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-left: 30px;
  border-radius: 50%;
  background: #1b1d21;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.editor__add:before,
.editor__add:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #ffffff;
}

.editor__add:before {
  width: 8px;
  height: 2px;
}

.editor__add:after {
  width: 2px;
  height: 8px;
}

.editor__add:hover {
  background: rgba(27, 29, 33, 0.8);
}

.editor__body {
  padding: 32px 24px;
}

.editor__textarea {
  width: 100%;
  height: 110px;
  border: none;
  background: none;
  resize: none;
  font-size: 14px;
}

.editor__gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 360px;
  margin: -16px 0 0 -8px;
}

.editor__preview {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 16px 8px 0;
  font-size: 0;
  cursor: pointer;
}

.editor__pic {
  border-radius: 8px;
}

.editor__close {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.editor__close .icon {
  font-size: 6px;
  fill: #1b1d21;
  -webkit-transition: -webkit-transform 0.25s;
  transition: -webkit-transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  transition: transform 0.25s, -webkit-transform 0.25s;
}

.editor__close:hover .icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.editor__preview:hover .editor__close {
  visibility: visible;
  opacity: 1;
}

.editor__btn {
  min-width: 232px;
  margin-top: 24px;
}

.editor__btn.btn_wide {
  min-width: 100%;
}

.editor .files {
  width: 100%;
  margin-top: 24px;
}

body.dark .editor__wrap {
  background: rgba(228, 228, 228, 0.03);
}

body.dark .editor__head {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .editor__action {
  background: rgba(228, 228, 228, 0.1);
}

body.dark .editor__action.active {
  background: #1b1d21;
}

body.dark .editor__textarea {
  color: #ffffff;
}

body.dark .editor__close {
  background: #242731;
}

body.dark .editor__close .icon {
  fill: #ffffff;
}

.notification__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -18px 0 28px -18px;
}

.notification__tag {
  margin: 18px 0 0 18px;
  border: 2px solid #e4e4e4;
  border-radius: 12px;
  padding: 6px 16px;
  line-height: 1.42857;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.notification__tag:hover,
.notification__tag.active {
  background: #ff0062;
  border-color: #ff0062;
  color: #ffffff;
}

.notification__container {
  position: relative;
  padding: 32px;
  border-radius: 24px;
  background: #ffffff;
}

.notification__container:before {
  content: "";
  position: absolute;
  top: 32px;
  left: 32px;
  right: 32px;
  bottom: -32px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.notification__title {
  margin-bottom: 32px;
}

.notification__list {
  margin: 0 -16px;
}

.notification__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  border-radius: 12px;
  background: transparent;
  color: #808191;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.notification__ava {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  font-size: 0;
}

.notification__ava > .notification__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.notification__status {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  font-size: 0;
}

.notification__details {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 24px;
}

.notification__author {
  margin-bottom: 9px;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.notification__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.notification__line .notification__category {
  margin-right: 8px;
}

.notification__info {
  margin-right: 8px;
  color: #5f75ee;
}

.notification__item .notification__text {
  margin-bottom: 16px;
}

.notification__item .notification__text p:not(:last-child) {
  margin-bottom: 20px;
}

.notification__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.notification__item.active {
  background: #ff0062;
  color: #ffffff;
}

.notification__item.active .actions__btn {
  border-color: #ffffff;
}

.notification__item.active .actions__btn .icon {
  fill: #ffffff;
}

.notification__item.active .notification__title,
.notification__item.active .notification__author,
.notification__item.active .notification__info {
  color: #ffffff;
}

.notification__preview {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  font-size: 0;
}

.notification__preview:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 7px;
  right: 7px;
  height: 7px;
  border-radius: 0 0 7px 7px;
}

.notification__preview.bg-purple-light:before {
  background: rgba(207, 200, 255, 0.5);
}

.notification__preview.bg-blue-light:before {
  background: rgba(160, 215, 231, 0.5);
}

.notification__preview.bg-yellow:before {
  background: rgba(255, 206, 115, 0.5);
}

.notification__preview .notification__pic {
  max-height: 100%;
}

.notification__btns {
  padding-top: 24px;
  text-align: center;
}

.notification__btn {
  min-width: 180px;
}

.notification__photo {
  margin-bottom: 40px;
  font-size: 0;
}

.notification__photo .notification__pic {
  width: 100%;
}

.notification__body {
  padding: 0 24px;
}

.notification__body:not(:last-child) {
  margin-bottom: 32px;
}

.notification__stage {
  margin-bottom: 16px;
}

.notification__parameters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}

.notification__parameter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5f75ee;
}

.notification__parameter:not(:last-child) {
  margin-right: 16px;
}

.notification__icon {
  margin-right: 10px;
  font-size: 0;
}

body.dark .notification__container {
  background: #242731;
}

body.dark .notification__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .notification__tag {
  border-color: rgba(228, 228, 228, 0.1);
  color: #ffffff;
}

body.dark .notification__tag:hover {
  border-color: #ff0062;
}

body.dark .notification__item:hover {
  background: rgba(228, 228, 228, 0.04);
}

body.dark .notification__item.active {
  background: #ff0062;
}

body.dark .notification__item.active .notification__author {
  color: #ffffff;
}

body.dark .notification__item.active .notification__info {
  color: #5f75ee;
}

body.dark .notification__author {
  color: #ffffff;
}

.filters__container {
  position: relative;
  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
}

.filters__container:before {
  content: "";
  position: absolute;
  top: 32px;
  left: 17px;
  right: 17px;
  bottom: -32px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.filters__item:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e4e4e4;
}

.filters__title {
  margin-bottom: 24px;
}

.filters__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.filters .checkbox:not(:last-child) {
  margin-bottom: 20px;
}

.filters__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.filters__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
}

.filters__btn {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 10px);
  flex: 0 0 calc(50% - 10px);
  min-width: calc(50% - 10px);
  height: 44px;
  margin: 0 5px;
  padding: 0;
  font-size: 14px;
}

.filters__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
}

.filters .radio:not(:last-child) {
  margin-right: 32px;
}

.filters__reset {
  line-height: 1.42857;
  font-weight: 600;
  color: #808191;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.filters__reset:hover {
  color: #ff0062;
}

body.dark .filters__container {
  background: #242731;
}

body.dark .filters__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .filters__item {
  border-color: rgba(228, 228, 228, 0.1);
}

.activity__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity__col:first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.activity__col:nth-child(2) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 426px;
  padding: 32px 0 0 65px;
}

.activity__container {
  position: relative;
  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
}

.activity__container:before {
  content: "";
  position: absolute;
  top: 26px;
  left: 19px;
  right: 19px;
  bottom: -26px;
  z-index: -1;
  background: #e3e6ec;
  opacity: 0.91;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

.activity__title {
  margin-bottom: 32px;
}

.activity__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.activity__variants {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 130px;
  padding-right: 15px;
}

.activity__variants .checkbox:not(:last-child) {
  margin-bottom: 16px;
}

.activity__list {
  margin-right: -16px;
}

.activity__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 16px;
  overflow: hidden;
  border-radius: 12px;
  color: #808191;
  cursor: pointer;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.activity__item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-transition: background 0.25s;
  -o-transition: background 0.25s;
  transition: background 0.25s;
}

.activity__item:hover {
  background: rgba(228, 228, 228, 0.3);
}

.activity__item.active {
  color: #ffffff;
}

.activity__item.active:before {
  background: #ff0062;
}

.activity__ava {
  position: relative;
  z-index: 2;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  font-size: 0;
}

.activity__ava > .activity__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
}

.activity__status {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff9a7b;
  border: 2px solid #ffffff;
  font-size: 0;
}

.activity__details {
  position: relative;
  z-index: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 32px 0 24px;
}

.activity__author {
  margin-bottom: 9px;
  color: #11142d;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.activity__details .activity__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.activity__line .activity__category {
  margin-right: 8px;
}

.activity__info {
  margin-right: 8px;
  color: #5f75ee;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}

.activity__item .activity__text p:not(:last-child) {
  margin-bottom: 20px;
}

.activity__item.active .activity__author,
.activity__item.active .activity__info {
  color: #ffffff;
}

.activity__preview {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 96px;
  height: 84px;
  background: #a0d7e7;
  border-radius: 8px;
  font-size: 0;
}

.activity__preview:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 7px;
  right: 7px;
  height: 7px;
  border-radius: 0 0 7px 7px;
  background: #e7faff;
}

.activity__preview .activity__pic {
  max-height: 100%;
}

.activity__btns {
  padding-top: 24px;
  text-align: center;
}

.activity__btn {
  min-width: 180px;
}

.activity:not(:first-child) {
  margin-top: 48px;
}

body.dark .activity__container {
  background: #242731;
}

body.dark .activity__container:before {
  background: #000;
  opacity: 0.51;
  -webkit-filter: blur(86.985px);
  filter: blur(86.985px);
  border-radius: 24px;
}

body.dark .activity__author {
  color: #ffffff;
}

body.dark .activity__item:hover {
  background: rgba(228, 228, 228, 0.04);
}

body.dark .activity__item.active .activity__author {
  color: #ffffff;
}

.field__label {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.33333;
  font-weight: 500;
  color: #b2b3bd;
}

.field__input,
.field__textarea {
  width: 100%;
  border-radius: 8px;
  background: rgba(228, 228, 228, 0.3);
  border: 2px solid transparent;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.field__input:focus,
.field__textarea:focus {
  border-color: #ff0062;
  background: #ffffff;
}

.field__input {
  height: 56px;
  padding: 0 23px;
}

.field__textarea {
  height: 157px;
  padding: 15px 23px;
  resize: none;
}

body.dark .field__input,
body.dark .field__textarea {
  color: #ffffff;
  background: rgba(228, 228, 228, 0.04);
}

.popup {
  position: relative;
  max-width: 630px;
  margin: 15px auto;
  background: #ffffff;
  border-radius: 24px;
}

.popup__form {
  padding: 32px;
  border-radius: 24px;
}

.popup__title {
  margin-bottom: 48px;
}

.popup__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -13px 0 32px -13px;
}

.popup__tag {
  margin: 13px 0 0 13px;
  border: 2px solid #ff754c;
  border-radius: 12px;
  padding: 6px 16px;
  line-height: 1.42857;
  font-weight: 600;
  color: #11142d;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.popup__tag:hover,
.popup__tag.active {
  background: #ff0062;
  border-color: #ff0062;
  color: #ffffff;
}

.popup__select {
  min-width: 100%;
  height: 56px;
  margin-bottom: 32px;
  padding: 0 40px 0 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 12px;
  background: rgba(228, 228, 228, 0.25)
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none'%3E%3Cpath fill-rule='evenodd' d='M9.293.333a.5.5 0 0 1 .39.812L5.39 6.512a.5.5 0 0 1-.781 0L.317 1.146a.5.5 0 0 1 .39-.812h8.586z' fill='%23b5b5be'/%3E%3C/svg%3E")
    no-repeat calc(100% - 16px) 50%/11px 7px;
  font-size: 14px;
  font-weight: 600;
  color: #808191;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.popup__select::-ms-expand {
  display: none;
}

.popup__select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.popup__user:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e4e4e4;
}

.popup__category {
  margin-bottom: 32px;
  color: #b2b3bd;
}

.popup__line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup__ava {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  font-size: 0;
}

.popup__ava .popup__pic {
  width: 100%;
  min-height: 100%;
  border-radius: 50%;
  image-orientation: from-image;
}

.popup__details {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 64px);
  flex: 0 0 calc(100% - 64px);
  width: calc(100% - 64px);
  padding-left: 40px;
}

.popup__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}

.popup__btns .popup__btn {
  min-width: 166px;
  height: 40px;
}

.popup__btns .popup__btn:not(:last-child),
.popup__loading:not(:last-child) {
  margin-right: 16px;
}

.popup__loading {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}

.popup__file {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 140px;
  opacity: 0;
}

.popup__loading:hover .popup__btn {
  background: #5a49ce;
}

.popup__text {
  color: #b2b3bd;
}

.popup__fieldset {
  margin-bottom: 24px;
}

.popup__fieldset > .popup__field:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -8px;
}

.popup__row:not(:last-child) {
  margin-bottom: 20px;
}

.popup__row .popup__field {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 16px);
  flex: 0 0 calc(50% - 16px);
  width: calc(50% - 16px);
  margin: 0 8px;
}

.popup .mfp-close {
  position: absolute;
  top: 20px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  line-height: normal;
  opacity: 1;
  font-size: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%2311142d' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E")
    no-repeat 50% 50%/10px auto;
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  -o-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.popup .mfp-close:hover {
  -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
}

.mfp-container {
  padding: 20px;
}

.mfp-bg {
  background: rgba(27, 29, 33, 0.7);
}

.mfp-zoom-in .popup {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-zoom-in.mfp-removing .popup {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

body.dark .popup {
  background: #242731;
}

body.dark .popup__tag {
  color: #ffffff;
}

body.dark .popup__user {
  border-color: rgba(228, 228, 228, 0.1);
}

body.dark .popup .mfp-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23ffffff' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E");
}

body.dark .popup .mfp-close:hover {
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
}

body.dark .popup__select {
  background-color: rgba(228, 228, 228, 0.04);
}

body.dark .popup__btns .popup__btn.btn_gray {
  background: none;
}

body.dark .mfp-bg {
  background: rgba(27, 29, 33, 0.9);
}

.color-green {
  color: #7fba7a;
}

.color-red {
  color: #ff754c;
}

.color-white {
  color: #ffffff;
}

.color-blue {
  color: #3f8cff;
}

.color-blue-dark {
  color: #5f75ee;
}

.color-purple {
  color: #ff0062;
}

.color-gray {
  color: #808191;
}

.bg-blue {
  background: #3f8cff;
}

.bg-blue-opacity {
  background: rgba(63, 140, 255, 0.3);
}

.bg-blue-light {
  background: #a0d7e7;
}

.bg-blue-light-opacity {
  background: rgba(160, 215, 231, 0.3);
}

.bg-yellow {
  background: #ffce73;
}

.bg-yellow-opacity {
  background: rgba(255, 206, 115, 0.3);
}

.bg-yellow-light {
  background: #ffe2ac;
}

.bg-pink {
  background: #ffa2c0;
}

.bg-pink-opacity {
  background: rgba(255, 162, 192, 0.3);
}

.bg-pink-light {
  background: #ffebf6;
}

.bg-green {
  background: #7fba7a;
}

.bg-green-opacity {
  background: rgba(127, 186, 122, 0.3);
}

.bg-purple {
  background: #ff0062;
}

.bg-purple-opacity {
  background: rgba(108, 93, 211, 0.3);
}

.bg-purple-light {
  background: #cfc8ff;
}

.bg-red {
  background: #ff754c;
}

.bg-red-opacity {
  background: rgba(255, 117, 76, 0.3);
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.desktop-show {
  display: none;
}

.tablet-show {
  display: none;
}

.mobile-show {
  display: none;
}

.title {
  font-size: 16px;
  line-height: 1.1875;
  font-weight: 600;
}

.title_lg,
.title_md,
.title_sm {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  letter-spacing: -1px;
}

.title_lg {
  font-size: 96px;
  line-height: 1.25;
}

.title_md {
  font-size: 72px;
  line-height: 1.22222;
}

.title_sm {
  font-size: 64px;
  line-height: 1.125;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Poppins", sans-serif;
}

.h1,
.h2,
.h3 {
  font-weight: 600;
  letter-spacing: -1px;
}

.h4,
.h5,
.h6 {
  font-weight: 500;
}

.h1 {
  font-size: 56px;
  line-height: 1.14286;
}

.h2 {
  font-size: 48px;
  line-height: 1.5;
}

.h3 {
  font-size: 40px;
  line-height: 1.5;
}

.h4 {
  font-size: 32px;
  line-height: 1.5;
  letter-spacing: -0.5px;
}

.h5 {
  font-size: 24px;
  line-height: 1.33333;
}

.h6 {
  font-size: 18px;
  line-height: 1.33333;
}

.caption {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38462;
}

.caption-sm {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33333;
}

@media only screen and (max-width: 1339px) and (max-width: 767px) {
  .widget_responsive:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .schedules__col:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .schedules__col:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
}

@media (hover: hover) {
  .switch:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape),
  screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media only screen and (max-width: 1419px) {
  .page__col {
    padding: 0 48px 44px;
  }
  .page__col:first-child {
    /* -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 390px);
    flex: 0 0 calc(100% - 390px);
    max-width: calc(100% - 390px); */
  }
  .page__col:nth-child(2) {
    width: 390px;
  }
  .page__content {
    padding: 0 48px 48px;
  }
  .widget__details {
    padding-left: 16px;
  }
  .banner__preview {
    width: 450px;
  }
}

@media only screen and (max-width: 1339px) {
  .page__center {
    width: 100%;
    max-width: 930px;
    padding: 0 70px 40px;
  }
  .page__row {
    display: block;
  }
  .page__row_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
    padding: 40px 0;
  }
  .page__row_head .page__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    margin: 0 16px;
    padding: 0;
  }
  .page__row_border .page__col:nth-child(2) {
    border: none;
  }
  .page__row_responsive {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -40px;
  }
  .page__row_responsive.page__row_head {
    padding: 0;
  }
  .page__row_responsive.page__row_head .page__col {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .page__row_responsive .page__col {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .page__row_responsive .page__col:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }
  .page__row_responsive .page__col:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    border-left: 1px solid #e4e4e4;
  }
  .page__col {
    padding: 0;
  }
  .page__col:first-child {
    max-width: 100%;
  }
  .page__col:nth-child(2) {
    width: 100%;
  }
  .page__row:not(.page__row_head):not(.page__row_responsive)
    .page__col:nth-child(2) {
    margin-top: 32px;
  }
  .page .page__col.page__col_w50 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
  }
  .page__group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin: 0 -16px;
  }
  .page__group .widget {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    margin: 0 16px;
  }
  .page__group .widget:not(:last-child) {
    margin-bottom: 0;
  }
  .page__content {
    padding: 0 0 40px;
  }
  .page__content_pt64 {
    margin-top: 32px;
    padding: 0;
    border: none;
  }
  .page__hello {
    font-size: 18px;
    line-height: 1.33333;
  }
  .page_other .page__center {
    padding-bottom: 0;
  }
  .sidebar__details {
    padding: 10px 10px 25px;
  }
  .sidebar__details:before {
    display: none;
  }
  .widget__details {
    padding-left: 24px;
  }
  .widget_responsive {
    position: relative;
    padding: 32px;
    background: #ffffff;
    border-radius: 24px;
  }
  .widget_responsive:before {
    content: "";
    position: absolute;
    top: 22px;
    left: 18px;
    right: 18px;
    bottom: -40px;
    z-index: -2;
    background: #e3e6ec;
    opacity: 0.91;
    -webkit-filter: blur(86.985px);
    filter: blur(86.985px);
    border-radius: 24px;
  }
  .header {
    max-width: 100%;
  }
  .header .search {
    width: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 50px;
  }
  .banner__container {
    padding: 50px 56px;
  }
  .banner__preview {
    right: 50px;
    width: 330px;
  }
  .sorting__row {
    display: block;
    margin: 0;
  }
  .sorting__col {
    width: 100%;
    margin: 0;
  }
  .sorting__col:not(:last-child) {
    margin-bottom: 16px;
  }
  .schedules__container {
    display: block;
    padding: 0;
    background: none;
    border-radius: 0;
  }
  .schedules__container:before {
    display: none;
  }
  .schedules__col {
    position: relative;
    padding: 32px;
    border-radius: 24px;
    background: #ffffff;
  }
  .schedules__col:before {
    content: "";
    position: absolute;
    top: 35px;
    left: 35px;
    right: 35px;
    bottom: -35px;
    z-index: -5;
    background: #e3e6ec;
    opacity: 0.91;
    -webkit-filter: blur(86.985px);
    filter: blur(86.985px);
    border-radius: 24px;
  }
  .schedules__col:first-child {
    width: 100%;
    margin-bottom: 32px;
  }
  .schedules__col:first-child .schedules__title {
    display: block;
  }
  .schedules__col:nth-child(2) {
    padding-left: 32px;
  }
  .schedules__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -32px -32px 0;
  }
  .schedules__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 32px 16px 0;
  }
  .schedules__item:not(:last-child) {
    margin-bottom: 0;
  }
  .schedules__btn {
    display: inline-block;
    margin-top: 32px;
  }
  .schedules__graphic {
    max-width: 100%;
  }
  .schedules__status:not(:last-child) {
    margin-right: auto;
  }
  body.dark .schedules__container {
    background: none;
  }
  body.dark .schedules__col {
    background: #242731;
  }
  body.dark .schedules__col:before {
    background: #000;
    opacity: 0.51;
    -webkit-filter: blur(86.985px);
    filter: blur(86.985px);
    border-radius: 24px;
  }
  .tabs__preview {
    display: none;
  }
  .inbox__details {
    padding-left: 16px;
  }
  .inbox__control {
    margin-bottom: 32px;
  }
  .inbox__btns .inbox__btn {
    min-width: 120px;
    height: 48px;
  }
  .files__list {
    display: block;
    margin: 0;
  }
  .files__list .files__item {
    width: 100%;
    margin: 0;
  }
  .files__list .files__item:not(:last-child) {
    margin-bottom: 16px;
  }
  .notification__details {
    padding-right: 0;
  }
  .notification__preview {
    display: none;
  }
  .activity__col:nth-child(2) {
    width: 370px;
    padding-left: 32px;
  }
  .activity__inner {
    display: block;
  }
  .activity__variants {
    display: none;
  }
  .activity__details {
    padding-right: 0;
  }
  .activity__preview {
    display: none;
  }
  .h1 {
    font-size: 48px;
    line-height: 1.5;
  }
  .h2 {
    font-size: 40px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 1179px) {
  .page__row_responsive {
    display: block;
    margin: 0;
  }
  .page__row_responsive.page__row_head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -16px;
    padding: 40px 0;
  }
  .page__row_responsive.page__row_head .page__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 32px);
    flex: 0 0 calc(50% - 32px);
    max-width: calc(50% - 32px);
    margin: 0 16px;
    padding: 0;
  }
  .page__row_responsive .page__col {
    padding-left: 0;
    padding-right: 0;
  }
  .page__row_responsive .page__col:first-child {
    max-width: 100%;
  }
  .page__row_responsive .page__col:nth-child(2) {
    max-width: 100%;
    border: none;
  }
  .page__row:not(.page__row_head) .page__col:nth-child(2) {
    margin-top: 32px;
  }
  .page .page__col.page__col_w50 {
    padding: 0;
  }
  .page .page__col.page__col_w50:first-child {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .page .page__col.page__col_w50:nth-child(2) {
    max-width: 100%;
  }
  .page .page__col.page__col_w50:not(:last-child) {
    margin-bottom: 0;
  }
  .page__row:not(.page__row_head) .page__col.page__col_w50:not(:last-child) {
    margin-bottom: 32px;
  }
  .page__flex {
    display: block;
  }
  .page__flex .widget:first-child {
    width: 100%;
    margin: 0 0 32px;
  }
  .page__welcome_sm {
    font-size: 32px;
  }
  .page__nav {
    margin-bottom: 32px;
  }
  .page.toggle .page__wrapper {
    padding-left: 96px;
  }
  .page_other {
    overflow: hidden;
  }
  .page_other .page__center {
    padding-bottom: 40px;
  }
  .sidebar {
    padding-top: 96px;
    overflow: hidden;
    -webkit-transition: width 0.25s, -webkit-transform 0.25s;
    transition: width 0.25s, -webkit-transform 0.25s;
    -o-transition: width 0.25s, transform 0.25s;
    transition: width 0.25s, transform 0.25s;
    transition: width 0.25s, transform 0.25s, -webkit-transform 0.25s;
  }
  .sidebar__top {
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    height: 96px;
    padding-left: 40px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .sidebar__top .sidebar__logo {
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  .sidebar__logo .sidebar__pic {
    width: 140px;
  }
  .sidebar__wrapper .sidebar__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar__burger {
    display: none;
  }
  .sidebar__group:not(:last-child):before {
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .sidebar__caption {
    white-space: nowrap;
    -webkit-transition: padding 0.25s;
    -o-transition: padding 0.25s;
    transition: padding 0.25s;
  }
  .sidebar__item {
    position: relative;
    padding-left: 3px;
  }
  .sidebar__icon {
    width: 56px;
    height: 56px;
    margin-right: 0;
    -webkit-transition: margin 0.25s;
    -o-transition: margin 0.25s;
    transition: margin 0.25s;
  }
  .sidebar__counter {
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .sidebar__user {
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
  .sidebar__bottom {
    -webkit-transition: padding 0.25s;
    -o-transition: padding 0.25s;
    transition: padding 0.25s;
  }
  .sidebar__banner {
    display: none;
  }
  .sidebar.active {
    width: 96px;
  }
  .sidebar.active .sidebar__inner {
    width: 56px;
  }
  .sidebar.active .sidebar__burger {
    border-color: #e4e4e4;
  }
  .sidebar.active .sidebar__top .sidebar__logo {
    opacity: 0;
  }
  .sidebar.active .sidebar__caption {
    padding: 0;
    text-align: center;
  }
  .sidebar.active .sidebar__caption span {
    display: none;
  }
  .sidebar.active .sidebar__group:before {
    left: -20px;
    right: -20px;
  }
  .sidebar.active .sidebar__item {
    padding-left: 0;
  }
  .sidebar.active .sidebar__counter {
    position: absolute;
    top: 15px;
    left: 22px;
    min-width: 10px;
    height: 10px;
    font-size: 0;
  }
  .sidebar.active .sidebar__user {
    padding-left: 8px;
  }
  .sidebar.active .sidebar__bottom {
    padding: 0 35px;
  }
  .sidebar.active .sidebar__bottom .switch_theme .switch__in {
    padding-left: 60px;
  }
  .banner__wrap {
    max-width: 300px;
  }
  .banner__title {
    margin-bottom: 6px;
    line-height: 1.2;
  }
  .banner__preview {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 180px;
  }
  .sorting__line .sorting__search {
    margin-right: 30px;
  }
  .dropdown__body {
    right: 0;
    width: auto;
    padding: 24px 21px;
  }
  .products__cell {
    padding-left: 15px;
  }
  .products__preview {
    width: 86px;
    height: 64px;
  }
  .products__details {
    padding-left: 16px;
  }
  .products__bg {
    border-radius: 50%;
  }
  .products__color .products__text {
    display: none;
  }
  .tabs__preview {
    display: block;
  }
  .inbox__control {
    display: block;
  }
  .inbox__btns {
    margin: 0 -8px;
  }
  .inbox__btns .inbox__btn {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 16px);
    flex: 0 0 calc(50% - 16px);
    min-width: calc(50% - 16px);
    margin: 0 8px;
  }
  .inbox__btns .inbox__btn:not(:last-child) {
    margin-right: 8px;
  }
  .inbox__more {
    display: none;
  }
  .inbox__box:not(:last-child) {
    margin-bottom: 32px;
    padding: 0;
    border: none;
  }
  .inbox__box .inbox__title {
    font-size: 18px;
  }
  .notification__details {
    padding: 0 24px;
  }
  .notification__preview {
    display: block;
  }
  .activity__row {
    display: block;
  }
  .activity__col:first-child {
    margin-bottom: 32px;
  }
  .activity__col:nth-child(2) {
    width: 100%;
    padding: 0;
  }
  .activity__details {
    padding: 0 24px;
  }
  .activity__preview {
    display: block;
  }
  .desktop-hide {
    display: none;
  }
  .desktop-show {
    display: block;
  }
  .desktop-text-right {
    text-align: right;
  }
}

@media only screen and (max-width: 1023px) {
  .page {
    overflow: hidden;
  }
  .page__wrapper {
    padding-left: 96px;
  }
  .page__center {
    padding: 0 32px 40px;
  }
  .page__row_head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin: 0;
    padding: 25px 0;
  }
  .page__row_head .page__col {
    max-width: 100%;
    margin: 0;
  }
  .page__row_head .page__col:nth-child(2) {
    margin-bottom: 30px;
  }
  .page__row_responsive.page__row_head {
    margin: 0;
    padding: 25px 0;
  }
  .page__row_responsive.page__row_head .page__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    margin: auto;
  }
  .page__row_responsive.page__row_head .page__col:nth-child(2) {
    margin-bottom: 30px;
  }
  .page__content {
    padding: 0;
  }
  .page.toggle .page__wrapper {
    -webkit-transform: translateX(160px);
    -ms-transform: translateX(160px);
    transform: translateX(160px);
  }
  .sidebar {
    z-index: 10;
    width: 96px;
  }
  .sidebar__inner {
    width: 56px;
  }
  .sidebar__top {
    padding: 0;
  }
  .sidebar__top .sidebar__logo {
    opacity: 0;
  }
  .sidebar__wrapper .sidebar__logo {
    height: 80px;
  }
  .sidebar__burger {
    border-color: #e4e4e4;
  }
  .sidebar__group:not(:last-child):before {
    left: -20px;
    right: -20px;
  }
  .sidebar__caption {
    padding-left: 0;
    text-align: center;
  }
  .sidebar__caption span {
    display: none;
  }
  .sidebar__item {
    padding-left: 0;
  }
  .sidebar__item:hover {
    color: #808191;
  }
  .sidebar__icon {
    margin-right: 21px;
  }
  .sidebar__item:hover .icon {
    fill: #1b1d21;
    opacity: 0.4;
  }
  .sidebar__counter {
    position: absolute;
    top: 15px;
    left: 22px;
    min-width: 10px;
    height: 10px;
    font-size: 0;
  }
  .sidebar__profile {
    margin-top: 20px;
  }
  .sidebar__details {
    padding: 5px 0 17px;
    background: none;
  }
  .sidebar__link .sidebar__icon {
    width: 56px;
    margin-right: 24px;
    padding: 0;
  }
  .sidebar__user {
    padding-left: 8px;
  }
  .sidebar__bottom {
    padding: 0 35px;
  }
  .sidebar .switch_theme .switch__in {
    padding-left: 60px;
  }
  .sidebar.active {
    width: 256px;
  }
  .sidebar.active .sidebar__inner {
    width: 100%;
  }
  .sidebar.active .sidebar__burger {
    border-color: transparent;
  }
  .sidebar.active .sidebar__top {
    padding-left: 40px;
  }
  .sidebar.active .sidebar__top .sidebar__logo {
    opacity: 1;
  }
  .sidebar.active .sidebar__icon {
    margin-right: 0;
  }
  .sidebar.active .sidebar__caption {
    padding: 0 20px;
    text-align: left;
  }
  .sidebar.active .sidebar__caption span {
    display: inline;
  }
  .sidebar.active .sidebar__group:before {
    left: 20px;
    right: 20px;
  }
  .sidebar.active .sidebar__wrapper .sidebar__logo {
    height: 0;
  }
  .sidebar.active .sidebar__item {
    padding-left: 3px;
  }
  .sidebar.active .sidebar__counter {
    position: static;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .sidebar.active .sidebar__user {
    padding: 0 20px;
  }
  .sidebar.active .sidebar__bottom {
    padding: 0 40px;
  }
  .sidebar.active .sidebar__bottom .switch_theme .switch__in {
    padding-left: 56px;
  }
  body.dark .sidebar__burger {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .sidebar.active .sidebar__burger {
    border-color: transparent;
  }
  .banner__container {
    padding: 50px 40px;
  }
  .products__preview {
    display: none;
  }
  .products__details {
    padding: 0;
  }
  .products__more {
    padding-top: 32px;
  }
  .schedules__task .schedules__text {
    padding: 0 10px;
  }
  .editor__close {
    top: 5px;
    right: 5px;
    visibility: visible;
    opacity: 1;
  }
  .tablet-hide {
    display: none;
  }
  .tablet-show {
    display: block;
  }
  .tablet-text-right {
    text-align: right;
  }
  .h3 {
    font-size: 32px;
    line-height: 1.5;
    letter-spacing: -0.5px;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (max-width: 900px) {
  .mfp-container {
    padding: 30px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page__wrapper {
    padding-left: 0;
  }
  .page__center {
    padding: 0 16px 32px;
  }
  .page__row_head {
    padding: 0;
  }
  .page__row_head .page__col:first-child {
    padding: 16px 16px 28px;
  }
  .page__row_head .page__col:nth-child(2) {
    margin: 0;
  }
  .page__row_responsive.page__row_head {
    padding: 0;
  }
  .page__row_responsive.page__row_head .page__col:nth-child(2) {
    margin-bottom: 0;
  }
  .page__widgets {
    display: block;
    margin: 0;
  }
  .page__widgets:not(:last-child) {
    margin-bottom: 32px;
  }
  .page__widgets .widget {
    width: 100%;
    margin: 0;
  }
  .page__widgets .widget:not(:last-child) {
    margin-bottom: 32px;
  }
  .page__group {
    display: block;
    margin: 0;
  }
  .page__group .widget {
    max-width: 100%;
    margin: 0;
  }
  .page__group .widget:not(:last-child) {
    margin-bottom: 32px;
  }
  .page__stat {
    padding: 32px 0;
  }
  .page__stat:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .page__stat_sm .sorting {
    position: relative;
    top: auto;
    right: auto;
  }
  .page__hello {
    font-size: 16px;
  }
  .page__title {
    padding: 0 24px;
  }
  .page.toggle .page__wrapper {
    padding-left: 0;
    -webkit-transform: translateX(256px);
    -ms-transform: translateX(256px);
    transform: translateX(256px);
  }
  body.dark .page__stat:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .sidebar {
    width: 256px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .sidebar__wrapper {
    padding: 20px;
  }
  .sidebar__inner {
    width: 100%;
  }
  .sidebar__top {
    padding-left: 40px;
    border-bottom: 1px solid #e4e4e4;
  }
  .sidebar__top .sidebar__logo {
    opacity: 1;
  }
  .sidebar__close {
    display: inline-block;
  }
  .sidebar__wrapper .sidebar__logo {
    display: none;
  }
  .sidebar__burger {
    display: none;
  }
  .sidebar__list {
    margin: 0;
  }
  .sidebar__group:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  .sidebar__group:not(:last-child):before {
    left: 20px;
    right: 20px;
  }
  .sidebar__caption {
    padding-left: 20px;
    text-align: left;
  }
  .sidebar__caption span {
    display: inline;
  }
  .sidebar__item {
    padding-left: 3px;
  }
  .sidebar__icon {
    margin-right: 0;
  }
  .sidebar__counter {
    position: static;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .sidebar__profile {
    display: none;
  }
  .sidebar__bottom {
    padding: 0 40px;
  }
  .sidebar .switch_theme .switch__in {
    padding-left: 56px;
  }
  .sidebar.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .sidebar.active .sidebar__caption {
    padding-left: 15px;
  }
  .sidebar.active .sidebar__item {
    padding-left: 0;
  }
  body.dark .sidebar__top {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .slider {
    margin-bottom: 48px;
  }
  .slider__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 0 32px 54px;
    text-align: center;
  }
  .slider__details {
    width: 100%;
    padding: 0;
  }
  .slider__preview {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 282px;
    height: 282px;
    padding: 20px;
  }
  .slider__text {
    margin-bottom: 24px;
  }
  .slider .owl-dots {
    left: 0;
    right: 0;
    bottom: 24px;
  }
  .widget__chart_impressions {
    margin-left: -10px;
    margin-right: 10px;
  }
  .widget__chart_items {
    margin-bottom: 16px;
  }
  .widget_shadow:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .widget_stat:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .widget_shadow:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .widget_stat:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .header {
    height: 96px;
    max-width: calc(100% + 32px);
    margin: 0 -16px;
    padding: 0 32px;
    border-bottom: 1px solid #e4e4e4;
  }
  .header .search {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .header .notifications {
    margin-right: 25px;
  }
  .header__user {
    display: block;
  }
  .header__burger {
    display: inline-block;
  }
  .header__search {
    display: inline-block;
  }
  body.dark .header {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .search {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    visibility: hidden;
  }
  .search.show {
    visibility: visible;
    opacity: 1;
  }
  .search__input {
    height: 56px;
    background: #e4e4e4;
    border-radius: 0;
  }
  .search__result {
    top: 0;
    left: 0;
    right: 0;
    -webkit-box-shadow: 0 30px 30px rgba(27, 29, 33, 0.3);
    box-shadow: 0 30px 30px rgba(27, 29, 33, 0.3);
  }
  .search.active .search__input {
    background: #e4e4e4;
  }
  body.dark .search__input {
    background: #1f2128;
  }
  body.dark .search__result {
    -webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
  }
  body.dark .search.active .search__input {
    background: #1f2128;
  }
  .notifications {
    position: static;
  }
  .notifications__open {
    width: 40px;
    height: 40px;
    -webkit-box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
    box-shadow: 0 5px 10px rgba(227, 230, 236, 0.6);
  }
  .notifications__open .icon {
    font-size: 20px;
  }
  .notifications__counter {
    top: 5px;
    right: 5px;
    min-width: 12px;
    height: 12px;
    font-size: 0;
  }
  .notifications__wrap {
    top: calc(100% + 1px);
    right: 0;
    left: 0;
    width: auto;
    padding: 24px 16px;
    -webkit-box-shadow: 0 30px 30px rgba(27, 29, 33, 0.3);
    box-shadow: 0 30px 30px rgba(27, 29, 33, 0.3);
  }
  .notifications__info {
    margin-bottom: 16px;
    padding: 0;
  }
  .notifications__list {
    margin-bottom: 24px;
  }
  .notifications__item {
    padding: 0;
  }
  .notifications__item:hover {
    background: none;
  }
  .notifications__item:not(:last-child) {
    margin-bottom: 20px;
  }
  .notifications__ava {
    margin-right: 16px;
  }
  body.dark .notifications__open {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
  body.dark .notifications__wrap {
    -webkit-box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
  }
  .banner__container {
    padding: 10px 32px 32px;
    text-align: center;
  }
  .banner__wrap {
    max-width: 100%;
  }
  .banner__preview {
    position: static;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    width: 260px;
    height: 260px;
    padding: 30px;
    margin: 0 auto;
    text-align: center;
  }
  .sorting {
    padding: 0 24px;
  }
  .sorting__dropdowns {
    display: block;
    margin: 0;
  }
  .sorting__dropdowns .dropdown {
    width: 100%;
    margin: 0;
  }
  .sorting__dropdowns .dropdown:not(:last-child) {
    margin-bottom: 16px;
  }
  .sorting__line {
    display: block;
  }
  .sorting__line .sorting__search {
    margin: 0 0 16px;
  }
  .sorting__actions {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .products__table {
    display: block;
    /* margin-top: -72px; */
  }
  .products__row {
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
  }
  .products__row:not(.products__row_head) {
    border-top: 1px solid #e4e4e4;
  }
  .products__row_head .products__cell {
    padding: 0;
  }
  .products__cell {
    display: block;
    padding: 0;
    border: none;
  }
  .products__cell:first-child {
    margin-right: 24px;
  }
  .products__cell:nth-child(n + 3) {
    display: none;
  }
  .products__preview {
    display: block;
    width: 72px;
    height: 55px;
  }
  .products__details {
    padding-left: 24px;
  }
  .products__bg {
    width: 9px;
    height: 32px;
    margin-right: 31px;
    border-radius: 9px;
  }
  .products__more {
    border-top: 1px solid #e4e4e4;
    padding: 24px 24px 0;
  }
  .products__more .products__btn {
    min-width: 100%;
  }
  .products__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .products_statement .products__cell {
    display: block;
  }
  .products_statement .products__cell:not(:first-child):not(:nth-child(3)) {
    /* display: none; */
  }
  body.dark .products__row:not(.products__row_head) {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .products__more {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .details__container {
    padding-bottom: 32px;
  }
  .details__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .details__title {
    margin-bottom: 24px;
  }
  .details__row {
    display: block;
  }
  .details__col:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: auto;
    margin: 0 -5px 32px;
    padding: 0;
  }
  .details__top {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    margin: 0 5px;
    padding: 0;
    border: none;
  }
  .details__bottom {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    margin: 0 5px;
  }
  .details__statistics {
    margin-bottom: 32px;
  }
  .details__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .details__item:not(:last-child) {
    border-right: none;
  }
  .details__list_three .details__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .details__list_three .details__item:not(:first-child) {
    border-top: 1px solid #e4e4e4;
  }
  .details__list_four .details__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
  }
  .details__list_four .details__item:nth-child(2n + 1) {
    border-right: 1px solid #e4e4e4;
  }
  .details__list_four .details__item:nth-child(n + 3) {
    border-top: 1px solid #e4e4e4;
  }
  .details .dropdown {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .details_statements:not(:last-child) {
    margin-bottom: 32px;
  }
  .details_statements .details__col:first-child {
    width: auto;
    padding: 0;
  }
  body.dark .details__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .details__list_four .details__item:nth-child(n + 3) {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .details__list_three .details__item:not(:first-child) {
    border-color: rgba(228, 228, 228, 0.1);
  }
  .schedules__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .schedules__list {
    display: block;
    margin: 0 -16px;
  }
  .schedules__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
  }
  .schedules__item:not(:last-child) {
    margin-bottom: 24px;
  }
  .schedules__top {
    margin: -16px 0 20px;
  }
  .schedules__arrows {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .schedules__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -32px;
    padding-bottom: 32px;
    overflow: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  .schedules__inner::-webkit-scrollbar {
    display: none;
  }
  .schedules__inner:before,
  .schedules__inner:after {
    content: "";
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 32px;
    height: 1px;
  }
  .schedules__graphic {
    min-width: 586px;
    margin-bottom: 7px;
  }
  .schedules__legend {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -10px;
    padding: 0;
  }
  .schedules__status {
    margin-top: 10px;
  }
  .schedules__status:not(:last-child) {
    margin-right: 15px;
  }
  body.dark .schedules__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .tabs__row {
    display: block;
    margin: -20px -16px;
  }
  .tabs__col:first-child {
    width: 100%;
  }
  .tabs__col:nth-child(2) {
    display: none;
  }
  .tabs__nav {
    display: none;
  }
  .payment__row {
    display: block;
  }
  .payment__details {
    width: 100%;
    margin: 0 0 32px;
  }
  .payment__tick {
    margin-right: 24px;
  }
  .inbox__tags {
    margin: -10px 0 24px -10px;
  }
  .inbox__tag {
    margin: 10px 0 0 10px;
  }
  .inbox__container {
    padding: 32px 24px;
  }
  .inbox__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .inbox__container > .inbox__title {
    margin: 0;
  }
  .inbox__list {
    margin: 0;
  }
  .inbox__item {
    padding: 24px 0;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  .inbox__head {
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .inbox__item .inbox__title {
    margin-left: -64px;
  }
  .inbox__text {
    margin-left: -64px;
  }
  .inbox__item:hover {
    background: none;
  }
  .inbox__item.active {
    background: none;
    color: #808191;
  }
  .inbox__item.active .actions__btn {
    border-color: #e4e4e4;
  }
  .inbox__item.active .actions__btn .icon {
    fill: #1b1d21;
  }
  .inbox__item.active .inbox__title {
    color: #11142d;
  }
  .inbox__item.active .inbox__author {
    color: #808191;
  }
  .inbox .actions {
    margin-left: -64px;
  }
  .inbox__foot {
    padding-top: 24px;
  }
  .inbox__box:not(:last-child) {
    margin-bottom: 24px;
  }
  .inbox__box .inbox__title {
    margin: 0 0 24px -64px;
  }
  .inbox .files {
    margin: 16px 0 0 -64px;
  }
  .inbox .sorting {
    padding: 0;
  }
  body.dark .inbox__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .inbox__item {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .inbox__item:hover {
    background: none;
  }
  body.dark .inbox__item.active {
    background: none;
  }
  body.dark .inbox__author {
    color: #ffffff;
  }
  body.dark .inbox__item.active .actions__btn {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .inbox__item.active .actions__btn .icon {
    fill: #ffffff;
  }
  .editor__head {
    padding: 24px 16px;
  }
  .editor__body {
    padding: 24px 16px;
  }
  .editor__btn {
    min-width: 100%;
    margin-top: 16px;
  }
  .notification__tags {
    margin: -10px 0 24px -10px;
  }
  .notification__tag {
    margin: 10px 0 0 10px;
  }
  .notification__container {
    padding: 32px 24px;
  }
  .notification__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .notification__title {
    margin-bottom: 16px;
  }
  .notification__list {
    margin: 0;
  }
  .notification__item {
    padding: 24px 0;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  .notification__details {
    padding: 0 0 0 16px;
  }
  .notification .actions {
    margin-left: -64px;
  }
  .notification__item:hover {
    background: none;
  }
  .notification__item.active {
    background: none;
    color: #808191;
  }
  .notification__item.active .actions__btn {
    border-color: #e4e4e4;
  }
  .notification__item.active .actions__btn .icon {
    fill: #1b1d21;
  }
  .notification__item.active .notification__title {
    color: #11142d;
  }
  .notification__item.active .notification__author {
    color: #5f75ee;
  }
  .notification__item.active .notification__info {
    color: #808191;
  }
  .notification__preview {
    display: none;
  }
  body.dark .notification__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .notification__item {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .notification__item:hover {
    background: none;
  }
  body.dark .notification__item.active {
    background: none;
  }
  body.dark .notification__item.active .actions__btn {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .notification__item.active .actions__btn .icon {
    fill: #ffffff;
  }
  .filters__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .filters__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .activity__col:nth-child(2) {
    padding: 0 16px;
  }
  .activity__container {
    padding: 32px 24px;
  }
  .activity__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  .activity__title {
    margin-bottom: 16px;
  }
  .activity__list {
    margin: 0;
  }
  .activity__item {
    padding: 24px 0;
    border-radius: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  .activity__item:before {
    display: none;
  }
  .activity__item.active {
    color: #808191;
  }
  .activity__details {
    padding: 0 0 0 16px;
  }
  .activity__item.active .activity__author {
    color: #11142d;
  }
  .activity__item.active .activity__info {
    color: #5f75ee;
  }
  .activity__preview {
    display: none;
  }
  .activity:not(:first-child) {
    margin-top: 32px;
  }
  body.dark .activity__container:before {
    -webkit-filter: blur(46.985px);
    filter: blur(46.985px);
  }
  body.dark .activity__item {
    border-color: rgba(228, 228, 228, 0.1);
  }
  body.dark .activity__item:hover {
    background: none;
  }
  .popup__title {
    margin-bottom: 42px;
  }
  .popup__tags {
    display: none;
  }
  .popup__user:not(:last-child) {
    padding-bottom: 24px;
  }
  .popup__ava {
    width: 80px;
    height: 80px;
  }
  .popup__details {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 80px);
    flex: 0 0 calc(100% - 80px);
    width: calc(100% - 80px);
    padding-left: 24px;
  }
  .popup__btns {
    display: block;
    margin: 0 0 32px;
  }
  .popup__btns .popup__btn {
    min-width: 100%;
  }
  .popup__btns .popup__btn.btn_gray {
    background: #ffffff;
  }
  .popup__btns .popup__btn:not(:last-child),
  .popup__loading:not(:last-child) {
    margin: 0;
  }
  .popup__text {
    margin-left: -104px;
  }
  .popup__row {
    display: block;
    margin: 0;
  }
  .popup__row .popup__field {
    width: 100%;
    margin: 0;
  }
  .popup__row .popup__field:not(:last-child) {
    margin-bottom: 24px;
  }
  .popup__form > .popup__btn {
    min-width: 100%;
  }
  .popup .mfp-close {
    right: 20px;
    -webkit-box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
    box-shadow: 0 5px 20px rgba(227, 230, 236, 0.85);
  }
  .mfp-container {
    padding: 0;
  }
  body.dark .popup .mfp-close {
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.35);
  }
  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  .mobile-text-right {
    text-align: right;
  }
  .h1 {
    font-size: 40px;
    line-height: 1.5;
  }
  .h2 {
    font-size: 32px;
    line-height: 1.5;
    letter-spacing: -0.5px;
  }
}

/*# sourceMappingURL=app.css.map */

@media (max-width: 450px) {
  .page__nav .page__link {
    padding: 8px;
  }
  .details__chart_report__xl {
    width: 100%;
    height: 100%;
  }
}
